const devEnvironment = {
  isDev: () => {
    return process.env.NODE_ENV === "development";
  },

  shouldUseDevState: () => {
    return process.env.NODE_ENV === "development";
    //return window.location.hostname === 'localhost';
  },

  shouldUseDevAPI: () => {
    return false;
    //return true;
    //return process.env.NODE_ENV === 'development';
    //return window.location.hostname === 'localhost';
  },
};

export default devEnvironment;
