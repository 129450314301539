import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const SqigglyLine = ({ size }) => (
  <FnrSvg size={size} doubleWidth viewBox="0 0 107.83 63.59">
    <FnrPath
      $virtue="temperance"
      d="m35.19,39.37c-2.34-.03-4.14-.77-5.68-2.19-1.28-1.19-2.54-2.4-3.86-3.55-1.74-1.52-3.72-1.76-5.84-.89-.73.3-1.32.82-1.86,1.4-.41.44-.81.9-1.22,1.34-.13.14-.25.27-.39.4-.75.71-1.44.57-2.08-.27-.29-.39-.31-.88,0-1.22,1.09-1.19,2.07-2.49,3.42-3.41,2.95-2.02,6.94-1.72,9.64.7,1.3,1.17,2.56,2.37,3.85,3.56,1.46,1.34,3.16,1.86,5.11,1.4.95-.22,1.78-.7,2.46-1.4,1.16-1.22,2.32-2.44,3.45-3.68,1.23-1.36,2.68-2.34,4.49-2.7,2.56-.52,4.86.06,6.83,1.76,1.27,1.09,2.47,2.25,3.69,3.39.83.77,1.71,1.45,2.86,1.67,1.95.37,3.67-.06,5.06-1.53,1.13-1.2,2.25-2.41,3.36-3.62,1.37-1.51,3.03-2.5,5.07-2.77,2.18-.29,4.2.21,5.91,1.6,1.34,1.1,2.58,2.32,3.85,3.5.86.81,1.76,1.55,2.94,1.8,2.11.46,3.91-.11,5.39-1.69.51-.55,1.01-1.1,1.52-1.64.13-.14.26-.27.42-.37.53-.36,1.07-.28,1.57.22.49.5.59,1.04.15,1.52-1.01,1.1-1.98,2.27-3.13,3.19-2.77,2.21-7.03,1.99-9.74-.4-1.32-1.16-2.6-2.37-3.88-3.57-1.1-1.03-2.38-1.64-3.91-1.65-1.34,0-2.55.42-3.51,1.35-1.02.98-1.95,2.05-2.92,3.08-.45.48-.89.97-1.37,1.43-2.98,2.85-7.39,3.06-10.6.44-1.12-.92-2.15-1.95-3.22-2.93-.38-.35-.77-.69-1.14-1.04-2.02-1.87-5.55-1.75-7.52.43-1.24,1.38-2.49,2.76-3.8,4.07-1.53,1.54-3.45,2.26-5.38,2.29Z"
    />
  </FnrSvg>
);

SqigglyLine.propTypes = { size: number };

export default SqigglyLine;
