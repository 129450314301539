import FnrEnvironment from "Utilities/FnrEnvironment";
import FnrFetch3 from "Utilities/FnrFetch/FnrFetch3";

import devAPI from "./API.dev.js";

let API = {
  login: (postData) => new FnrFetch3("login/submitLogin", postData),
  loginWithEmailPassword: (postData) =>
    new FnrFetch3("login/password", postData),
  sendTextCode: (postData) => new FnrFetch3("login/sendAuthCode", postData),
  loginWithTextCode: (postData) => new FnrFetch3("login/authCode", postData),
  loginWithTextCode2fa: (postData) =>
    new FnrFetch3("login/authCode2fa", postData),
  resend2fa: () => new FnrFetch3("login/resend2fa"),
  sendEmailLoginLink: (postData) =>
    new FnrFetch3("login/sendMagicLink", postData),
  instantlyAuthenticate: (route, postData) => new FnrFetch3(route, postData),
  genericEndpoint: (route, postData) => new FnrFetch3(route, postData),
};

if (FnrEnvironment.shouldUseDevAPI()) API = { ...API, ...devAPI };

export default API;
