import { number } from "prop-types";

import FnrPolygon from "../components/FnrPolygon";
import FnrSvg from "../components/FnrSvg";

const Plus = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 35.28">
    <FnrPolygon
      $virtue="faith"
      points="33.65 12.62 22.06 12.62 22.06 1.02 14.02 1.02 14.02 12.62 2.43 12.62 2.43 20.65 14.02 20.65 14.02 34.26 22.06 34.26 22.06 20.65 33.65 20.65 33.65 12.62"
    />
  </FnrSvg>
);

Plus.propTypes = { size: number };

export default Plus;
