export const getFundItStatus = {
  success: true,
  paymentsEnabled: true,
  userIsSuperAdmin: true,
  hasWebsite: true,
  hasAddress: true,
  addressInUS: true,
  hasDenomination: true,
  userHasFirstName: true,
  userHasLastName: true,
  userEmailVerified: true,
  userPhoneVerified: true,
  hasMerchantAccount: false,
  hasEnteredKYC: false,
  hasKYCValidationError: false,
  canGetPayouts: false,
  numberOfFunds: 3,
  termsOfService: "Blah blah blah blah",
  pricing: {
    creditCardPercentage: 0.029,
    achPercentage: 0.01,
    additionalFlatFee: 30,
    isForChildNetworks: false,
    comments: "Default Fee Calculation",
  },
};
