import { forwardRef } from "react";

import styled from "styled-components/macro";

import FnrIcon from "UI/FnrIcon";

import { FnrButtonWrapper } from "./FnrButton.js";

const ButtonIcon = forwardRef(({ ...props }, ref) => {
  return (
    <ButtonIconWrapper>
      <FnrIcon {...props} ref={ref} />
    </ButtonIconWrapper>
  );
});

ButtonIcon.displayName = "ButtonIcon";
export { ButtonIcon };

const ButtonIconWrapper = styled.div`
  ${FnrButtonWrapper}.small & {
    font-size: 16px;
    line-height: 16px;
  }
  ${FnrButtonWrapper}.large & {
    font-size: 22px;
    line-height: 22px;
  }
  ${FnrButtonWrapper}.xlarge & {
    font-size: 26px;
    line-height: 26px;
  }
`;

export const ButtonText = styled.div`
  position: relative;
  z-index: 1;
  ${ButtonIconWrapper} + & {
    padding-left: 8px;
    ${FnrButtonWrapper}.small & {
      padding-left: 6px;
    }
    ${FnrButtonWrapper}.large & {
      padding-left: 16px;
    }
  }
  & + ${ButtonIconWrapper} {
    padding-left: 8px;
    ${FnrButtonWrapper}.small & {
      padding-left: 6px;
    }
    ${FnrButtonWrapper}.large & {
      padding-left: 16px;
    }
  }
  ${FnrButtonWrapper}.large & {
    @media (max-width: 580px) {
      font-size: 18px;
    }
  }
  ${FnrButtonWrapper}.xlarge & {
    @media (max-width: 580px) {
      font-size: 22px;
    }
  }
  ${FnrButtonWrapper}.xlarge ${ButtonIconWrapper} + &, ${FnrButtonWrapper}.xlarge & + ${ButtonIconWrapper} {
    padding-left: 18px;
  }
`;
