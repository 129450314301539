const fnrIconButtonVariants = {
  default: "default",
  danger: "danger",
  dark: "dark",
  draggable: "draggable",
  error: "error",
  warning: "warning",
  secondary: "secondary",
  inverted: "inverted",
};

export default fnrIconButtonVariants;
