import React from "react";

import styled from "styled-components/macro";

import { useFlocknoteAppState } from "Flocknote/state";

import { AnimatePresence } from "framer-motion";

import Portal from "Utilities/PortalMaker";
import { usePortalElement } from "Utilities/PortalMaker/usePortalElement";

import InstantAuthContent from "./InstantAuthContent";
import InstantAuthNeedInfoError from "./InstantAuthNeedInfoError";

export default function InstantAuth() {
  const [
    {
      instantAuth: { displayInstantAuth, error },
    },
  ] = useFlocknoteAppState();

  const portalToElement = usePortalElement("#InstantAuthOverlay");

  return (
    <Portal to={portalToElement}>
      <AnimatePresence>
        {displayInstantAuth ? (
          <Overlay>
            {error === "invalid_info" ? (
              <InstantAuthNeedInfoError />
            ) : (
              <InstantAuthContent />
            )}
          </Overlay>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8001;
`;
