import { useContext, useEffect, useMemo, useState } from "react";

import { FnrSlidesContext, fnrSlidesVariants } from "./FnrSlides";

/**
 * Returns the index of the displaying component.
 * @returns {number}
 */
const useReducedIdx = () => {
  const { activeKey, components } = useContext(FnrSlidesContext);

  return useMemo(() => {
    if (typeof activeKey === "number") return activeKey % components.length;

    for (let idx = 0; idx < components.length; idx++) {
      if (components[idx].key === activeKey) return idx;
    }
  }, [activeKey, components]);
};

/**
 * Returns the string version of an active key even if the passed in activeKey is an index.
 */
const useActiveKeyAsString = () => {
  const { activeKey, components } = useContext(FnrSlidesContext);
  const reducedIdx = useReducedIdx();

  return useMemo(() => {
    if (typeof activeKey === "string") return activeKey;
    return components[reducedIdx].key;
  }, [activeKey, components, reducedIdx]);
};

/**
 * Returns the direction to which the transition should go.
 * @returns {"right"|"left"}
 */
const useDirection = () => {
  const reducedIdx = useReducedIdx();
  const [previousIdx, setPreviousIdx] = useState(reducedIdx);
  const [currentDirection, setCurrentDirection] = useState("left");

  useEffect(() => {
    setPreviousIdx(reducedIdx);
  }, [reducedIdx]);

  return useMemo(() => {
    let newCurrentDirection = currentDirection;
    if (previousIdx < reducedIdx) newCurrentDirection = "left";
    else if (previousIdx > reducedIdx) newCurrentDirection = "right";
    setCurrentDirection(newCurrentDirection);
    return newCurrentDirection;
  }, [currentDirection, previousIdx, reducedIdx]);
};

const useVariant = () => {
  const { variant } = useContext(FnrSlidesContext);
  return variant;
};

const useAxis = () => {
  const { axis } = useContext(FnrSlidesContext);
  return axis;
};

const xSubtleShift = "10%";
const ySubtleShift = "30%";

const xStandardShift = "40";
const yStandardShift = "40";

const initialVars = {
  top: { transform: `translateY(${ySubtleShift})`, x: 0, y: yStandardShift },
  bottom: {
    transform: `translateY(-${ySubtleShift})`,
    x: 0,
    y: -yStandardShift,
  },
  left: { transform: `translateX(${xSubtleShift})`, x: xStandardShift, y: 0 },
  right: {
    transform: `translateX(-${xSubtleShift})`,
    x: -xStandardShift,
    y: 0,
  },
};

const useInitial = (direction) => {
  const variant = useVariant();
  const { transform, x, y } = useMemo(
    () => initialVars[direction],
    [direction]
  );

  return useMemo(() => {
    switch (variant) {
      case fnrSlidesVariants.subtle:
        return { opacity: 0, transform };
      case fnrSlidesVariants.standard:
      default:
        return {
          x,
          y,
          opacity: 0,
        };
    }
  }, [transform, variant, x, y]);
};

const exitVars = {
  top: { transform: `translateY(-${ySubtleShift})`, x: 0, y: -yStandardShift },
  bottom: { transform: `translateY(${ySubtleShift})`, x: 0, y: yStandardShift },
  left: {
    transform: `translateX(-${xSubtleShift}%)`,
    x: -xStandardShift,
    y: 0,
  },
  right: { transform: `translateX(${xSubtleShift}%)`, x: xStandardShift, y: 0 },
};

const useExit = (direction) => {
  const variant = useVariant();
  const { currentWidth } = useContext(FnrSlidesContext);

  const { transform, x, y } = exitVars[direction];

  return useMemo(() => {
    switch (variant) {
      case fnrSlidesVariants.zoom:
        return {
          opacity: 0,
          position: "absolute",
          scale: 0,
          width: "100%",
          top: 0,
        };
      case fnrSlidesVariants.subtle:
        return {
          opacity: 0,
          position: "absolute",
          top: 0,
          transform,
          width: "100%",
          minWidth: `${currentWidth}px`,
        };
      case fnrSlidesVariants.standard:
      default:
        return {
          x,
          y,
          opacity: 0,
          position: "absolute",
          width: "100%",
          transition: { type: "tween", duration: 0.2, ease: "easeOut" },
          minWidth: `${currentWidth}px`,
        };
    }
  }, [transform, variant, x, y, currentWidth]);
};

const useInitialProps = () => {
  const variant = useVariant();
  const axis = useAxis();
  const direction = useDirection();
  const initialRight = useInitial("right");
  const initialLeft = useInitial("left");
  const initialTop = useInitial("top");
  const initialBottom = useInitial("bottom");

  return useMemo(() => {
    if (variant === fnrSlidesVariants.zoom)
      return {
        opacity: 0,
        scale: 0,
      };
    if (axis === "x") {
      if (direction === "right") return initialRight;
      return initialLeft;
    }
    if (axis === "y") {
      if (direction === "right") return initialBottom;
      return initialTop;
    }
  }, [
    axis,
    direction,
    initialBottom,
    initialLeft,
    initialRight,
    initialTop,
    variant,
  ]);
};

const useAnimateProps = () => {
  const variant = useVariant();
  const axis = useAxis();

  return useMemo(() => {
    const xy = axis.toUpperCase();
    switch (variant) {
      case fnrSlidesVariants.zoom:
        return { scale: 1, opacity: 1 };
      case fnrSlidesVariants.subtle:
        return { opacity: 1, transform: `translate${xy}(0%)` };
      case fnrSlidesVariants.standard:
      default:
        return {
          x: 0,
          y: 0,
          opacity: 1,
          transition: { delay: 0.05 },
        };
    }
  }, [variant, axis]);
};

const useExitProps = () => {
  const axis = useAxis();
  const direction = useDirection();
  const exitRight = useExit("right");
  const exitLeft = useExit("left");
  const exitTop = useExit("top");
  const exitBottom = useExit("bottom");

  return useMemo(() => {
    if (axis === "x") {
      if (direction === "right") return exitRight;
      return exitLeft;
    }
    if (axis === "y") {
      if (direction === "right") return exitBottom;
      return exitTop;
    }
  }, [axis, direction, exitRight, exitLeft, exitTop, exitBottom]);
};

const useAnimationPropTransition = () => {
  const variant = useVariant();

  return useMemo(() => {
    switch (variant) {
      case fnrSlidesVariants.subtle:
        return { duration: 0.4, ease: "easeOut" };
      case fnrSlidesVariants.standard:
      default:
        return { type: "tween" };
    }
  }, [variant]);
};

const useAnimationProps = () => {
  const initial = useInitialProps();
  const animate = useAnimateProps();
  const exit = useExitProps();
  const transition = useAnimationPropTransition();

  return useMemo(
    () => ({
      initial,
      animate,
      exit,
      transition,
    }),
    [animate, exit, initial, transition]
  );
};

export { useActiveKeyAsString, useDirection, useAnimationProps };
