const center = ({ $noCenter }) =>
  $noCenter
    ? ""
    : `
  display: flex;
  width: 100%;
  justify-content: center;
`;

export default center;
