import React from "react";

import { HoverParentInside, HoverStateProvider } from "./";

export function HoverParent({
  children,
  hoverStyle,
  selectedStyle,
  triggerResize = 0,
  hoverMargin = 4,
  style,
  CustomSelectedElement,
  CustomHoverElement,
  disabled,
  allowOverflow,
}) {
  return (
    <HoverStateProvider>
      <HoverParentInside
        hoverStyle={hoverStyle}
        selectedStyle={selectedStyle}
        triggerResize={triggerResize}
        style={style}
        hoverMargin={hoverMargin}
        disabled={disabled}
        CustomSelectedElement={CustomSelectedElement}
        CustomHoverElement={CustomHoverElement}
        allowOverflow={allowOverflow}
      >
        {children}
      </HoverParentInside>
    </HoverStateProvider>
  );
}
