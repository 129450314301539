import { bool, number, string } from "prop-types";
import { useEffect, useState } from "react";

import { useTheme } from "styled-components";
import styled from "styled-components/macro";

import UnoSolid from "UI/images/UnoSolid";

const InnerDiv = styled("div")`
  @keyframes moveBackAndForth {
    0%,
    100% {
      left: 0;
      transform: rotateY(0deg);
    }
    49% {
      transform: rotateY(0deg);
    }
    50% {
      left: calc(100% - 50px);
      transform: rotateY(180deg);
    }
    99% {
      transform: rotateY(180deg);
    }
  }
  position: absolute;
  animation: moveBackAndForth 1.5s linear infinite; /* Apply animation */
`;

const OuterDiv = styled("div")`
  position: relative;
  height: ${({ $noHeight }) => ($noHeight ? "0px" : "54px")};
  min-width: ${({ $width }) => ($width ? "100px" : "500px")};
  ${({ $width }) => ($width ? `width: ${$width};` : "")}
  transition: height 300ms;
`;

/**
 *
 * @param {object} param0
 * @param {color} param0.color - what color should uno be?
 * @param {boolean} param0.noHeightBeforeRun - Take up no space while waiting for uno to run.
 * @param {number} param0.timeout - When should uno start running in ms.
 * @returns
 */
const RunningUno = ({ color, width, noHeightBeforeRun, timeout = 2000 }) => {
  const { colors, palette } = useTheme();
  const [running, setRunning] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isMounted) {
        setRunning(true);
      }
    }, timeout);
  }, [isMounted, timeout]);

  return (
    <OuterDiv
      $width={width}
      $noHeight={noHeightBeforeRun && !running}
      data-cy="RunningUno_Container"
    >
      {running && (
        <InnerDiv>
          <UnoSolid
            color={colors[color] || palette[color] || colors.fnBlue}
            size={48}
          />
        </InnerDiv>
      )}
    </OuterDiv>
  );
};

RunningUno.propTypes = {
  color: string,
  width: string,
  noHeightBeforeRun: bool,
  timeout: number,
};

export default RunningUno;
