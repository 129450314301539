/**
 * Sets one border to the divider color to visually seperate the element.
 */
const divide = ({
  $vertical,
  theme: {
    colors: { divider },
  },
}) => `border-${$vertical ? "right" : "bottom"}: solid 1px ${divider};`;

export default divide;
