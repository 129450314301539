import { number } from "prop-types";
import { forwardRef, useEffect, useRef, useState } from "react";

import styled from "styled-components/macro";
import { height } from "theme";

import classNames from "classnames";
import { motion } from "framer-motion";

import FnrLoadingBricks from "UI/FnrLoadingBricks2";
import { useFnrModalState } from "UI/FnrModal";
import { colors } from "UI/colors";

import { colorIsLight, lightenDarkenColor } from "Utilities/Colors/colorUtils";

const FnrButton = forwardRef(
  (
    {
      children,
      text,
      loading = false,
      onClick,
      onHoverStart,
      onHoverEnd,
      forceHover = false,
      disabled = false,
      color = "gray",
      size,
      fullWidth,
      borderRadius = 8,
      innerBorderRadius,
      style = {},
      onTouchEnd,
      focusSize,
      className,
      stopPropagation,
      preventDefault,
      height: heightArg,
      ...props
    },
    forwardedRef
  ) => {
    const internalRef = useRef();
    const measurements = useRef();

    const buttonRef = forwardedRef || internalRef;

    const fnrModalState = useFnrModalState();

    useEffect(() => {
      measureButton();
    }, [fnrModalState?.hasAnimatedIn]);

    const [measured, setMeasured] = useState(false);
    const [tapping, setTapping] = useState(false);
    const [hovering, setHovering] = useState(false);

    const hoverStart = (e) => {
      onHoverStart && onHoverStart(e);
      if (!measurements.current) measureButton();
      setHovering(true);
    };
    const hoverEnd = (e) => {
      onHoverEnd && onHoverEnd(e);
      setHovering(false);
    };

    let hexColor = "#98a4a5";
    if (color.substr(0, 1) === "#") hexColor = color;
    else {
      const colorName = color.charAt(0).toUpperCase() + color.slice(1);
      if (colors["fn" + colorName]) {
        hexColor = colors["fn" + colorName];
      }
    }

    const hilightColor = lightenDarkenColor(hexColor, 18);
    const shineColor = lightenDarkenColor(hexColor, 40);

    const darkText = colorIsLight(lightenDarkenColor(hexColor, -40));

    const measureButton = () => {
      measurements.current = buttonRef.current.getBoundingClientRect();
      setMeasured(true);
    };

    const updatePointer = ({ offsetX, offsetY }) => {
      buttonRef.current.style.setProperty(
        "--fnrButtonShineX",
        (offsetX / measurements.current.width) * 100 + "%"
      );
      buttonRef.current.style.setProperty(
        "--fnrButtonShineY",
        (offsetY / measurements.current.height) * 100 + "%"
      );
    };

    useEffect(() => {
      measureButton();
      const button = buttonRef.current;
      button.addEventListener("pointermove", updatePointer);
      button.addEventListener("mouseover", measureButton);
      return () => {
        button.removeEventListener("pointermove", updatePointer);
        button.removeEventListener("mouseover", measureButton);
      };
    }, []);

    const tapStart = () => setTapping(true);
    const tapEnd = () => setTapping(false);

    const styles = {
      backgroundColor: hexColor,
      "--fnrButtonShineX": "50%",
      "--fnrButtonShineY": "50%",
      "--fnrButtonBgColor": hexColor,
      "--fnrButtonHilightColor": hilightColor,
      "--fnrButtonShineColor": shineColor,
    };

    if (measured) {
      styles["--fnrButtonShineRadius"] =
        Math.max(80, measurements.current.width, measurements.current.height) *
          2 +
        "px";
    }

    style = { ...styles, ...style };

    const handleClick = (e) => {
      if (stopPropagation) e.stopPropagation();
      if (preventDefault) e.preventDefault();
      if (onClick && !disabled) onClick();
    };

    if (typeof borderRadius === "number") {
      if (!innerBorderRadius) innerBorderRadius = borderRadius - 2 + "px";
      borderRadius = borderRadius + "px";
    }

    return (
      <FnrButtonWrapper
        data-cy="fnrButton"
        ref={buttonRef}
        onHoverStart={hoverStart}
        onHoverEnd={hoverEnd}
        onTapStart={tapStart}
        onTapCancel={tapEnd}
        onTap={tapEnd}
        onClick={handleClick}
        borderradius={borderRadius}
        $focusSize={focusSize}
        $height={heightArg}
        $noHeight={!heightArg} // Don't let the interpolation set height to 100%.
        className={`${classNames(size, {
          loading,
          disabled,
          forceHover,
          fullWidth,
          darkText,
        })} ${className}`}
        style={style}
        {...props}
      >
        <Border borderRadius={borderRadius}>
          <BorderHilight />
        </Border>
        <Inside borderRadius={innerBorderRadius || borderRadius}>
          <Shine />
          <Shadow />
          <ContentAround>
            <FnrLoadingBricks loading={loading}>
              <FnrButtonContent
                className="FnrButtonContent"
                animate={
                  disabled || loading
                    ? null
                    : {
                        scale: tapping
                          ? size === "small"
                            ? 0.97
                            : 0.96
                          : hovering
                          ? size === "small"
                            ? 1.03
                            : 1.04
                          : 1,
                      }
                }
              >
                {text}
                {children}
              </FnrButtonContent>
            </FnrLoadingBricks>
          </ContentAround>
        </Inside>
      </FnrButtonWrapper>
    );
  }
);

FnrButton.displayName = "FnrButton";
export default FnrButton;

export const FnrButtonWrapper = styled(motion.button)`
  background: var(--fnrButtonBgColor);
  padding: 2px;
  border-radius: ${(props) => props.borderradius};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  appearance: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 2px;
  transition: filter 0.2s;
  &.fullWidth {
    display: block;
    width: 100%;
  }
  &.loading {
    cursor: wait;
  }
  &.disabled {
    filter: grayscale(25%);
    cursor: not-allowed;
  }
  &.small {
    padding: 2px;
  }
  &:focus {
    outline: ${({ $focusSize }) =>
      $focusSize
        ? `${$focusSize || "1px"} solid var(--fnrButtonShineColor)`
        : `none`};
    outline-offset: 1px;
  }
  ${height}
`;
const Inside = styled.div`
  background: var(--fnrButtonBgColor);
  border-radius: ${(props) => props.borderRadius};
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 5;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      calc(var(--fnrButtonShineRadius) * 0.8) circle at 28% 20%,
      var(--fnrButtonHilightColor),
      transparent 40%
    );
    /* dumb old safari */
    @supports not (aspect-ratio: auto) {
      background: radial-gradient(
        calc(var(--fnrButtonShineRadius) * 4) circle at 28% 20%,
        var(--fnrButtonHilightColor),
        transparent 40%
      );
    }
    z-index: -1;
  }
`;
export const FnrButtonContent = styled(motion.div)`
  color: #fff;
  font-weight: 400;
  position: relative;
  z-index: 20;
  user-select: none;
  font-size: 18px;
  line-height: 22px;
  padding: 9px 15px;
  text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    content: "";
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
  }

  // prettier-ignore
  ${FnrButtonWrapper}.darkText & {
    color: #13426b;
  }

  // prettier-ignore
  ${FnrButtonWrapper}.fullWidth & {
    justify-content: center;
  }

  ${FnrButtonWrapper}.xlarge & {
    font-size: 26px;
    line-height: 30px;
    padding: 14px 23px;
  }

  ${FnrButtonWrapper}.large & {
    font-size: 22px;
    line-height: 24px;
    padding: 12px 19px;
  }

  ${FnrButtonWrapper}.small & {
    font-size: 14px;
    line-height: 18px;
    padding: 6px 11px;
  }
`;
const ContentAround = styled.div`
  z-index: 40;
  position: relative;
  width: 100%;
`;

const Border = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: ${(props) => props.borderRadius};
  z-index: 1;
  overflow: hidden;
`;
const BorderHilight = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(
    var(--fnrButtonShineRadius) circle at var(--fnrButtonShineX)
      var(--fnrButtonShineY),
    rgba(255, 255, 255, 0.6),
    transparent 40%
  );
  opacity: 0;
  transition: opacity 1s, background 0.2s;

  ${FnrButtonWrapper}:not(.loading):not(.disabled):hover &,
  ${FnrButtonWrapper}.forceHover & {
    opacity: 1;
    transition: opacity 0.5s, background 0.2s;
  }
`;

const Shine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(
    var(--fnrButtonShineRadius) circle at var(--fnrButtonShineX)
      var(--fnrButtonShineY),
    var(--fnrButtonShineColor),
    transparent 40%
  );
  /* dumb old safari */
  @supports not (aspect-ratio: auto) {
    background: radial-gradient(
      calc(var(--fnrButtonShineRadius) * 5) circle at var(--fnrButtonShineX)
        var(--fnrButtonShineY),
      var(--fnrButtonShineColor),
      transparent 40%
    );
  }
  opacity: 0;
  transition: opacity 0.8s, background 0.2s;
  z-index: 3;

  ${FnrButtonWrapper}:not(.loading):not(.disabled):hover &,
  ${FnrButtonWrapper}.forceHover & {
    opacity: 1;
    transition: opacity 0.4s, background 0.2s;
  }
`;
const Shadow = styled.div`
  position: absolute;
  z-index: 21;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0);
  border-radius: inherit;
  transition: box-shadow 0.2s;
  box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 3px;
  ${FnrButtonWrapper}:not(.loading):active & {
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 5px;
  }
`;

FnrButton.propTypes = { height: number };
