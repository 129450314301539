import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const DottedLine = ({ size }) => (
  <FnrSvg size={size} doubleWidth viewBox="0 0 82.1 23.62">
    <FnrPath
      $virtue="patience"
      d="m10.98,14.32h-4.5c-.93,0-1.69-.76-1.69-1.69s.76-1.69,1.69-1.69h4.5c.93,0,1.69.76,1.69,1.69s-.76,1.69-1.69,1.69Z"
    />
    <FnrPath
      $virtue="patience"
      d="m62.54,14.32h-8.59c-.93,0-1.69-.76-1.69-1.69s.76-1.69,1.69-1.69h8.59c.93,0,1.69.76,1.69,1.69s-.76,1.69-1.69,1.69Zm-17.19,0h-8.59c-.93,0-1.69-.76-1.69-1.69s.76-1.69,1.69-1.69h8.59c.93,0,1.69.76,1.69,1.69s-.76,1.69-1.69,1.69Zm-17.19,0h-8.59c-.93,0-1.69-.76-1.69-1.69s.76-1.69,1.69-1.69h8.59c.93,0,1.69.76,1.69,1.69s-.76,1.69-1.69,1.69Z"
    />
    <FnrPath
      $virtue="patience"
      d="m75.63,14.32h-4.5c-.93,0-1.69-.76-1.69-1.69s.76-1.69,1.69-1.69h4.5c.93,0,1.69.76,1.69,1.69s-.76,1.69-1.69,1.69Z"
    />
  </FnrSvg>
);

DottedLine.propTypes = { size: number };

export default DottedLine;
