import { useState } from "react";

import { useAnimationFrame } from "framer-motion";

import { HOUSEHOLD_PROFILE } from "./drawerKeys";

const useWidth = ({ drawerKey }) => {
  const [profileWidth, setProfileWidth] = useState(0);

  useAnimationFrame(() => {
    const { clientWidth: newProfileWidth = 0 } =
      document.querySelector(".user_profile_wrapper") || {};

    if (newProfileWidth !== profileWidth) setProfileWidth(newProfileWidth);
  });

  if (drawerKey === HOUSEHOLD_PROFILE) {
    return Math.max(720, profileWidth + 80);
  }

  return 400;
};

export { useWidth };
