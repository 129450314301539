const hoverFocus = ({
  theme: { colors, palette },
  disabled,
  $hoverFocusColor: colorArg,
  $hoverFocusBackgroundColor: backgroundColorArg,
}) => {
  const color = colors[colorArg] || palette[colorArg] || colorArg;
  const backgroundColor =
    colors[backgroundColorArg] ||
    palette[backgroundColorArg] ||
    backgroundColorArg;

  return `&:hover, &:focus {
    ${disabled ? `` : `opacity: 1;`}
    ${color ? `color: ${color};` : ``}
    ${backgroundColor ? `background-color: ${backgroundColor};` : ``}
  }`;
};

export default hoverFocus;
