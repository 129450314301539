/**
 * @template {Object<string,function>} AdditionalKeys
 * @param {string} FEATURE_KEY
 * @param {AdditionalKeys} additionalKeys
 * @returns {{all: () => string[]} & AdditionalKeys}
 */
export function createKeys(FEATURE_KEY, additionalKeys = {}) {
  return Object.keys(additionalKeys).reduce(
    (acc, key) => {
      acc[key] = (...args) => [FEATURE_KEY, ...additionalKeys[key](...args)];
      return acc;
    },
    {
      all: () => [FEATURE_KEY],
    }
  );
}

export const featureKeys = {
  FLOCKNOTE_FEATURE_KEY: "flocknote",
  DASHBOARD_CARDS_FEATURE_KEY: "dashboardCards",
  MMG_FEATURE_KEY: "mmg",
  SCHEDULED_NOTES_FEATURE_KEY: "scheduledNotes",
  GIFT_REFUND_FEATURE_KEY: "giftRefund",
  SACRAMENT_TRACKING_FEATURE_KEY: "sacramentTracking",
  USER_PROFILE_FEATURE_KEY: "userProfile",
  TWO_FA_LOGIN: "twoFALogin",
  TWO_FA_FEATURE_KEY: "twoFA",
  TWO_FA_ENROLLMENT_FEATURE_KEY: "twoFAEnrollment",
  TWO_FA_DISENROLLMENT_FEATURE_KEY: "twoFADisenrollment",
  REPORTS: "reports",
  UNSUBSCRIBE_CONTACT: "unsubscribeContact",
  CONTACTS_FEATURE_KEY: "contacts",
  HOUSEHOLD_DATABASE_FEATURE_KEY: "householdDatabase",
  HOUSEHOLD_PROFILE_FEATURE_KEY: "holdholdProfile",
  EXTENDED_FIELD_INPUTS: "extendedFieldInputs",
  EXTENDED_FIELDS: "extendedFields",
  PARISHES_PAGE_FEATURE_KEY: "parishesPage",
  GIVE_FEATURE_KEY: "give",
  ADD_HOUSEHOLD_MEMBER: "addHouseholdMember",
  SMART_GROUPS_FEATURE_KEY: "smartGroups",
};
