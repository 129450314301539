import styled from "styled-components/macro";

import { motion } from "framer-motion";

export const LoginWrapper = styled(motion.div)`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding: 30px 0;
  width: 340px;
  box-sizing: border-box;
  border-radius: 49px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 12px;
  background: linear-gradient(to bottom right, #016099, #13426b);
  color: #eceff0;
  overflow: hidden; // this does weird stuff when children slide back in forth in framer motion.

  @media (max-width: 360px) {
    width: min(340px, calc(100vw - 20px));
  }
`;

export const InputForm = styled.div`
  border-radius: 15px;
  border: 1px solid #bec3c7;
  overflow: hidden;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px inset;
  will-change: transform; // fixes weird bug where there is a flash of hard corners when autofilling or onMouseEnter/Exit
`;
export const InputWrapper = styled.div`
  position: relative;
  &:after {
    content: "";
    background: #eceff0;
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    border-radius: 11px;
    opacity: 0;
    transition: opacity 0.2s;
    overflow: hidden;
  }
  ${LoginWrapper}:not(.disabled) &:hover:not(:focus-within):after {
    opacity: 0.5;
  }
  &:focus-within:after {
    opacity: 1;
  }
`;
export const InputIcon = styled.div`
  position: absolute;
  height: 40px;
  top: 50%;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  z-index: 100;
  font-size: 24px;
  color: #828c8d;
  transition: color 0.2s;
  ${InputWrapper}:focus-within & {
    color: #278dc1;
  }
`;
export const Input = styled(motion.input)`
  appearance: none;
  border: none;
  box-sizing: border-box;
  background: none;
  margin: 0;
  padding: 18px 24px;
  width: 100%;
  font-size: 18px;
  position: relative;
  z-index: 50;
  padding-left: 60px;
  line-height: 24px;
  outline: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
    font-size: 18px;
  }
  &:-webkit-autofill::first-line {
    font-size: 18px;
  }
`;
export const Divider = styled.div`
  height: 1px;
  background: #bec3c7;
  margin: 0 14px;
`;

export const CodeInputWrapper = styled(InputWrapper)`
  &:after {
    opacity: 1;
  }
  ${LoginWrapper}:not(.disabled) &:hover:not(:focus-within):after {
    opacity: 1;
  }
`;

export const CodeInput = styled(Input)`
  font-size: 30px;
  letter-spacing: 15px;

  &::placeholder {
    letter-spacing: normal;
  }
`;
