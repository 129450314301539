import axios from "axios";

import { createUrl } from "./createUrl";

export default class FnrFetch3 {
  constructor(url, postData, options) {
    this.cancelSource = axios.CancelToken.source();

    if (url) this.url = url;
    if (postData) this.postData = postData;
    if (options) this.options = options;
  }

  createCaller = (url, verb = "post", data) => {
    if (!url && this.url) url = this.url;
    if (!data && this.postData) data = this.postData;

    return new Promise((fnrFetchResolve, fnrFetchReject) => {
      axios[verb](createUrl(url), data, {
        withCredentials: true,
        cancelToken: this.cancelSource.token,
        ...this.options,
      })
        .then((response) => {
          if (response.data.success) fnrFetchResolve(response.data);
          fnrFetchReject(response.data);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) fnrFetchReject(error);
        });
    });
  };

  get(url) {
    return this.createCaller(url, "get");
  }

  post(url, postData) {
    return this.createCaller(url, "post", postData);
  }

  patch(url, patchData) {
    return this.createCaller(url, "patch", patchData);
  }

  delete(url, patchData) {
    return this.createCaller(url, "delete", patchData);
  }

  getFormData(data = {}) {
    const formData = new FormData();

    if (typeof data === "object")
      for (let key in data) formData.append(key, data[key]);

    return formData;
  }

  cancel() {
    this.cancelSource.cancel();
  }

  mock(returnData, delay = 0.5) {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          returnData.success
            ? resolve(returnData)
            : reject(returnData.error, returnData),
        delay * 1000
      );
    });
  }
}

function getRandomLoadTime(min = 500, max = 1500) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const mockPromise = (resp, respOverride, loadTime) => {
  const success = respOverride ? respOverride.success : resp.success;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return success
        ? resolve(respOverride || resp)
        : reject(respOverride || resp);
    }, loadTime || getRandomLoadTime());
  });
};

const callMockResponse = (resp, loadTime) => {
  if (resp instanceof Function) resp = resp();
  return {
    get: (respOverride) => mockPromise(resp, respOverride, loadTime),
    post: (respOverride) => mockPromise(resp, respOverride, loadTime),
    patch: (respOverride) => mockPromise(resp, respOverride, loadTime),
    delete: (respOverride) => mockPromise(resp, respOverride, loadTime),
    cancel: () => {},
  };
};

export { callMockResponse };
