import { bool, node } from "prop-types";
import { forwardRef, useContext, useEffect, useState } from "react";

import styled from "styled-components/macro";

import { AnimatePresence, motion } from "framer-motion";

import FnrMovingDiv from "UI/FnrMovingDiv";

import { FnrSlidesContext } from "./FnrSlides";
import { useActiveKeyAsString, useAnimationProps } from "./hooks";

const SFnrMovingDiv = styled(FnrMovingDiv)`
  position: relative;
  ${({ widthMotion }) => (!widthMotion ? "width: 100%" : "")};
`;

const AnimationContainer = forwardRef(
  ({ isAnimating, children, clip }, ref) => {
    const { fullWidth, hideHeightMotion } = useContext(FnrSlidesContext);

    return (
      <SFnrMovingDiv
        muted={!isAnimating}
        heightMotion={!hideHeightMotion}
        widthMotion={!fullWidth}
        clip={clip}
        ref={ref}
      >
        {children}
      </SFnrMovingDiv>
    );
  }
);

AnimationContainer.propTypes = {
  isAnimating: bool,
  children: node,
  clip: bool,
};

const MotionContainer = forwardRef(({ clip }, ref) => {
  const { components, componentProps } = useContext(FnrSlidesContext);
  const activeKeyAsString = useActiveKeyAsString();
  const animationProps = useAnimationProps();

  const [activeKey, setActiveKey] = useState(activeKeyAsString);
  const [isAnimating, setIsAnimating] = useState(false);

  // The key should not actually change until 1 render after the exit props update.
  // This allows the component being removed to have its direction updated before being unmounted.
  useEffect(() => {
    setActiveKey(activeKeyAsString);
  }, [activeKeyAsString]);

  return (
    <AnimationContainer ref={ref} isAnimating={isAnimating} clip={clip}>
      <AnimatePresence initial={false}>
        {components.map(
          ({ value: Component, key }) =>
            key === activeKey && (
              <motion.div
                {...animationProps}
                key={key}
                onAnimationStart={() => setIsAnimating(true)}
                onAnimationComplete={() => setIsAnimating(false)}
              >
                <Component {...componentProps} />
              </motion.div>
            )
        )}
      </AnimatePresence>
    </AnimationContainer>
  );
});

MotionContainer.propTypes = { clip: bool };

export default MotionContainer;
