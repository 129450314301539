import { themeVariants } from "theme/constants";

const cursor = ({ $variant, disabled }) => {
  switch ($variant) {
    case themeVariants.draggable:
      return `
        cursor: grab;
        &:active {
          cursor: move;
          cursor: grabbing;
          }
        `;
    case themeVariants.info:
      return `cursor: default;`;
    case themeVariants.disabled:
      return `cursor: not-allowed;`;
    default:
      if (disabled) return `cursor: not-allowed;`;
      return `cursor: pointer;`;
  }
};

export default cursor;
