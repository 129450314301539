import { createGlobalStyle } from "styled-components";

export const colors = {
  // ################
  // Legacy & Misc
  // ################

  fnGreen: "#00B44E",
  fnDkBlueGray: "#2f3f4f",
  fnRed: "#f0243a",
  fnRedNotice: "#d5a223",
  fnDkRed: "#802428",
  fnPurple: "#8B169C",
  fnPurpleLt: "rgba(90, 22, 156, .13)",
  fnYellow: "#fccf6b",
  fnYellowNotice: "#f7f0b6",
  wofOrange: "#ed7722",

  // ################
  // Blues
  // ################

  fnBlue: "#278DC1", // equivalent to fnBlue600
  fnBlueHover: "#1c6e97", // equivalent to fnBlue700
  fnDkBlue: "#073a5f", // equivalent to fnBlue800

  fnBlue100: "#ecf4f9", // hsl(200, 51%, 95%)
  fnBlue200: "#c4e0ed", // hsl(200, 54%, 85%)
  fnBlue300: "#9bcbe4", // hsl(200, 57%, 75%)
  fnBlue400: "#70b8db", // hsl(200, 60%, 65%)
  fnBlue500: "#44a4d5", // hsl(200, 63%, 55%)
  fnBlue600: "#278DC1", // hsl(200, 66%, 45%)
  fnBlue700: "#1c6e97", // hsl(200, 69%, 35%)
  fnBlue800: "#073a5f", // hsl(205, 86%, 20%)
  fnBlue900: "#0a3043", // hsl(200, 75%, 15%)

  // ################
  // Grays
  // ################

  fnLtGray: "#eceff0", // equivalent to fnGray100
  fnBorderGray: "#e5e7eb",
  fnHeaderGray: "#F9FBFC",
  fnGray: "#98a4a5", // equivalent to fnGray400
  fnDkGray: "#828c8d", // equivalent to fnGray500
  fnTabSelectedGray: "#4d5f68",
  fnScrollbarDarkGray: "#4a5e72",

  fnGray100: "#eceff0", // hsl(195, 12%, 93%)
  fnGray200: "#cfd3d3", // hsl(180, 4%, 82%)
  fnGray300: "#b3babc", // hsl(193, 6%, 72%)
  fnGray400: "#98a4a5", // hsl(185, 7%, 62%)
  fnGray500: "#828c8d", // hsl(185, 5%, 53%)
  fnGray600: "#5d7479", // hsl(191, 13%, 42%)
  fnGray700: "#455a5f", // hsl(192, 16%, 32%)
  fnGray800: "#2d3f43", // hsl(191, 20%, 22%)
  fnGray900: "#182325", // hsl(189, 21%, 12%)
};

function createCSSColorVars() {
  return Object.keys(colors).map((color) => {
    return `--${color}: ${colors[color]};`;
  });
}

export const CSSColorVariables = createGlobalStyle`
  :root {
    ${createCSSColorVars()}
  }
`;
