import { themeSizes, themeVariants } from "theme/constants";

const negitiveMargin = ({ $variant, $size }) => {
  switch ($variant) {
    case themeVariants.icon:
      switch ($size) {
        case themeSizes.xs:
          return `
            padding: 4.5px 0px;
            margin: -4.5px 0px;
          `;
        case themeSizes.sm:
          return `
            padding: 5px 0px;
            margin: -5px 0px;
          `;
        case themeSizes.lg:
          return `
            padding: 7px 0px;
            margin: -7px 0px;
          `;
        case themeSizes.xl:
          return `
            padding: 8px 0px;
            margin: -8px 0px;
          `;
        case themeSizes.decorative1:
        case themeSizes.decorative2:
        case themeSizes.decorative3:
          return ""; // These will use the padding interpolation instead.
        case themeSizes.md:
        default:
          return `
            padding: 6px 0px;
            margin: -6px 0px;
          `;
      }
    case themeVariants.badge:
      return `
         margin: -4px -10px;
      `;
    case themeVariants.emphasized:
      return `
        padding: 4px 8px;
        margin: -4px -8px;
        `;
    default:
      return ``; // Since it is unusual to use negitive margins, the default is to not do anything.
  }
};

export default negitiveMargin;
