import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import styled from "styled-components/macro";

import { useFlocknoteAppState } from "Flocknote/state";

import {
  faExclamationCircle,
  faPen,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  LoginWrapper,
  NoStyleButton,
} from "Login/InstantAuth/InstantAuthContent";
import { SSO_KEYS } from "Payments/FundItPage/API";

export default function InstantAuthNeedInfoError() {
  const [state, flocknoteAppDispatch] = useFlocknoteAppState();

  const merchantCenterInstantAuth = window.location.pathname.includes(
    `/fundit/merchantcenter?ssoKey=${SSO_KEYS.initiateWePaySSO}`
  );

  const handleInfoUpdate = () => {
    if (merchantCenterInstantAuth) {
      const newUrl = `/dashboard/user/${state.instantAuth.userID}`;
      window.location.assign(newUrl);
    } else {
      const newUrl = `${window.location.pathname}/user/${state.instantAuth.userID}`;
      // Step 1: Navigate to the profile
      window.fnReactBus.goToUrl(newUrl);
      // Step 2: Close
      flocknoteAppDispatch({ type: "Hide Instant Auth" });
    }
  };

  const handleCancel = () => {
    flocknoteAppDispatch({ type: "Hide Instant Auth" });
  };

  return (
    <LoginWrapper
      key="instantAuthError"
      initial={{ scale: 0, opacity: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{
        opacity: 0,
        scale: 0.2,
        transition: { type: "tween", ease: "easeIn", duration: 0.25 },
      }}
    >
      <FontAwesomeIcon
        icon={faExclamationCircle}
        size="3x"
        style={{ marginBottom: "8px" }}
      />
      <div
        style={{ textAlign: "center", fontSize: "20px", marginBottom: "24px" }}
      >
        Sorry. You need both a verified email and phone to access this. Please
        update them in your profile.
      </div>

      <ButtonsWrapper>
        <NoStyleButton
          onClick={handleCancel}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FontAwesomeIcon icon={faTimes} style={{ marginRight: "4px" }} />
          Close
        </NoStyleButton>

        <NoStyleButton
          onClick={handleInfoUpdate}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Update Now
          <FontAwesomeIcon icon={faPen} style={{ marginLeft: "4px" }} />
        </NoStyleButton>
      </ButtonsWrapper>
    </LoginWrapper>
  );
}

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
`;
