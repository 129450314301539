import { QueryClient } from "@tanstack/react-query";

import { flocknoteKeys } from "Flocknote/API/keys";

const defaultQueryConfig = {
  staleTime: 5000,
};

export const queryClient = new QueryClient({
  defaultOptions: { queries: defaultQueryConfig },
});

// 🚀 everythingsourced from App state is assumed fresh
queryClient.setQueryDefaults(flocknoteKeys.all(), {
  staleTime: Infinity,
});
