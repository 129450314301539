import { bool, func, node, object, oneOf, oneOfType, string } from "prop-types";
import React, { forwardRef, useRef } from "react";

import styled from "styled-components/macro";

import { motion } from "framer-motion";

import FnrButtonWrapper from "UI/FnrButtonWrapper";
import { FnrPopover } from "UI/FnrPopover2";

import useIsHoverFocused from "Utilities/Hooks/useIsHoverFocused";

const ChildrenWrapper = forwardRef(
  ({ wrapInButton, children, ...rest }, ref) => {
    if (wrapInButton)
      return (
        <FnrButtonWrapper ref={ref} {...rest}>
          {children}
        </FnrButtonWrapper>
      );

    // Why are we using motion here?
    return (
      <motion.span ref={ref} {...rest}>
        {children}
      </motion.span>
    );
  }
);

ChildrenWrapper.propTypes = { wrapInButton: bool, children: node };

FnrTooltip.propTypes = {
  children: node,
  content: oneOfType([string, func, node]),
  maxWidth: string,
  color: oneOf(["blue", "red", "white"]),
  hide: bool,
  style: object,
  /** Pass in the ref to reveal the tooltip when hovered or focused */
  hoverFocusRef: object,
  /** Makes tooltip accessable if no hoverFocusRef. */
  wrapInButton: bool,
  "aria-label": string,
  onClick: func,
};

export default function FnrTooltip({
  children,
  content,
  maxWidth,
  color = "blue",
  hide,
  style,
  wrapInButton,
  hoverFocusRef,
  "aria-label": ariaLabel,
  onClick,
  ...rest
}) {
  const wrapperRef = useRef();
  const isHoverFocused = useIsHoverFocused(wrapperRef, hoverFocusRef);

  return (
    <Wrapper {...rest} style={style}>
      <ChildrenWrapper
        aria-label={ariaLabel}
        wrapInButton={wrapInButton}
        ref={wrapperRef}
        onClick={onClick}
      >
        {children}
      </ChildrenWrapper>

      <FnrPopover
        show={isHoverFocused && !hide}
        buttonRef={wrapperRef}
        color={color}
      >
        <Content style={{ maxWidth }} color={color}>
          {content}
        </Content>
      </FnrPopover>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  cursor: default;
`;

const Content = styled.div`
  padding: 1px 5px;
  color: ${({ color }) => (color === "white" ? "rgba(0, 0, 0, 0.9)" : null)};
`;
