import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const TriangleOutline = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 35.28">
    <FnrPath
      $virtue="veracity"
      d="M7.09,11.17l17.42,17.42H7.09V11.17M4.85,2.64c-.77-.77-2.17-.3-2.18.79,0,0,0,.02,0,.02v28.07c0,.82.67,1.49,1.49,1.49h28.07s.02,0,.02,0c1.08-.02,1.55-1.41.79-2.17L4.85,2.64Z"
    />
  </FnrSvg>
);

TriangleOutline.propTypes = { size: number };

export default TriangleOutline;
