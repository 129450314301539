import { createKeys, featureKeys } from "Utilities/ReactQuery/fnKeys";

const NETWORK_KEY = "network";
const USER_KEY = "user";
const FUNDIT_KEY = "fundIt";
const STAX_KEY = "stax";
const PERMISSION_KEY = "permissions";
const STATUS_KEY = "status";

export const flocknoteKeys = createKeys(featureKeys.FLOCKNOTE_FEATURE_KEY, {
  network: () => [NETWORK_KEY],
  user: () => [USER_KEY],
  fundItStatus: () => [FUNDIT_KEY, STATUS_KEY],
  fundItPermissions: () => [FUNDIT_KEY, PERMISSION_KEY],
  staxStatus: () => [STAX_KEY, STATUS_KEY],
});
