import { useRef, useState } from "react";

export function useKeepOpenList(defaultReasons = []) {
  const reasonsToKeepOpen = useRef(defaultReasons);

  const [showing, setShowing] = useState(defaultReasons.length > 0);

  const addReason = (reasonKey, autoUpdate = true) => {
    if (!reasonsToKeepOpen.current.includes(reasonKey))
      reasonsToKeepOpen.current = [reasonKey, ...reasonsToKeepOpen.current];
    if (autoUpdate) updateShowBasedOnReasonsList(reasonsToKeepOpen.current);
  };

  const removeReason = (reasonKey, autoUpdate = true, timeout = 100) => {
    setTimeout(() => {
      reasonsToKeepOpen.current = reasonsToKeepOpen.current.filter(
        (val) => val !== reasonKey
      );
      if (autoUpdate) updateShowBasedOnReasonsList(reasonsToKeepOpen.current);
    }, timeout);
  };

  const updateShowBasedOnReasonsList = (reasonsList) => {
    setShowing(reasonsList.length > 0);
  };

  const showPopover = () => {
    setShowing(true);
  };

  const hidePopover = (force = false) => {
    if (force) setShowing(false);
    else updateShowBasedOnReasonsList(reasonsToKeepOpen.current);
  };

  return {
    reasonsToKeepOpen,
    addReason,
    removeReason,
    showing,
    showPopover,
    hidePopover,
  };
}
