import { widthBySize } from "theme/constants";

const width = ({ $size, $width, $variant }) => {
  if ($size) return `width: ${widthBySize[$size]}px;`;
  if ($width) return `width: ${$width}px;`;
  switch ($variant) {
    default:
      return `width: 100%;`;
  }
};

export default width;
