import { useEffect, useRef } from "react";

import { install } from "resize-observer";

export default function useResizeObserver(element, callback, refreshTrigger) {
  const observer = useRef(null);

  useEffect(() => {
    if (!window.ResizeObserver) install();

    const el = element.current;

    if (observer?.current && element?.current) {
      observer.current.unobserve(element.current);
    }

    observer.current = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === element.current) callback(entry);
      }
    });
    observe();

    return () => {
      if (observer?.current && element?.current) {
        observer.current.unobserve(element.current);
      }
    };
  }, [element?.current, refreshTrigger]);

  const observe = () => {
    if (element?.current && observer.current) {
      observer.current.observe(element.current);
    }
  };
}
