import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components/macro";

import { motion } from "framer-motion";

import { HoverContext } from "./";

export const HoverChild = forwardRef(
  (
    {
      children,
      style,
      disabled = false,
      selected = false,
      forceHover = false,
      killHoverStyles,
    },
    forwardedRef
  ) => {
    const [
      { hoveringChildRef, forceHoverChildRef, setPointerPosition },
      dispatch,
    ] = useContext(HoverContext);

    const [firstLoad, setFirstLoad] = useState(true);

    const newRef = useRef();

    const childRef = forwardedRef || newRef;

    useEffect(() => {
      if (selected) {
        dispatch({
          type: "Set Selected Child",
          childRef,
        });
      } else if (!firstLoad) {
        dispatch({
          type: "Remove Selected Child",
          childRef,
        });
      }

      setFirstLoad(false);

      // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
      // eslint-disable-next-line
    }, [selected]);

    useEffect(() => {
      if (killHoverStyles) return;
      if (forceHover) {
        dispatch({
          type: "Set Force Hover Child",
          childRef,
        });
      } else if (!firstLoad && childRef === forceHoverChildRef) {
        dispatch({
          type: "Remove Force Hover Child",
          childRef,
        });
      }

      // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
      // eslint-disable-next-line
    }, [forceHover]);

    const onHoverStart = (event, info) => {
      if (killHoverStyles) return;
      dispatch({
        type: "Set Hovering Child",
        childRef,
        disabled,
        pointerPosition: info?.point,
      });
    };

    useEffect(() => {
      if (hoveringChildRef === childRef) onHoverStart();

      // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
      // eslint-disable-next-line
    }, [disabled]);

    return (
      <Wrapper
        ref={childRef}
        onHoverStart={onHoverStart}
        onTapStart={setPointerPosition}
        style={style}
        className="HoverChild"
      >
        {children}
      </Wrapper>
    );
  }
);

HoverChild.displayName = "HoverChild";

const Wrapper = styled(motion.div)`
  z-index: 2;
  flex-grow: 1;
`;
