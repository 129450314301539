import { createContext, useContext, useState, useEffect, useRef } from "react";

export const FnrModalState = createContext();

export function FnrModalProvider({
  children,
  show,
  setShow,
  positionTopIfMobile,
  onWillClose,
  onFinishedClosing,
  ...props
}) {
  const [hasAnimatedIn, setHasAnimatedIn] = useState(show);

  const isOpen = useRef(show);

  useEffect(() => {
    isOpen.current = show;
  }, [show]);

  useEffect(() => {
    if (onWillClose && hasAnimatedIn && !show) {
      onWillClose();
    }
  }, [hasAnimatedIn, onWillClose, show]);

  const animationComplete = () => {
    if (isOpen.current) setHasAnimatedIn(true);
    else if (onFinishedClosing) onFinishedClosing();
  };

  const context = {
    show,
    setShow,
    hasAnimatedIn,
    positionTopIfMobile,
    isTouchOnly: window.matchMedia("(any-hover: none)").matches,
    animationComplete,
    close: () => setShow(false),
    onWillClose,
    onFinishedClosing,
    ...props,
  };

  return (
    <FnrModalState.Provider value={context}>{children}</FnrModalState.Provider>
  );
}

export default function useFnrModalState() {
  const context = useContext(FnrModalState);

  return context;
}
