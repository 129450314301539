import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import styled from "styled-components/macro";

import classNames from "classnames";
import { motion, useAnimation } from "framer-motion";

import FnrIcon from "UI/FnrIcon";

// TODO: replace all instances of this with FnrResendCode
export default function ResendCodeButton({
  onClick,
  isLoading = false,
  disabled = false,
}) {
  const [isRotating, setIsRotating] = useState(false);

  useEffect(
    function startRotation() {
      if (isLoading) setIsRotating(true);
    },
    [isLoading]
  );

  return (
    <Button
      data-cy="resendCode"
      onClick={!disabled ? onClick : null}
      whileHover={!disabled && { scale: 1.05 }}
      whileTap={!disabled && { scale: 0.95 }}
      disabled={disabled}
    >
      <Text>Resend Code</Text>

      <Icon
        key={isLoading || isRotating ? "rotating" : "motionless"}
        animate={
          (isLoading || isRotating) && {
            rotate: 360,
            transition: {
              duration: 1.2,
              repeat: 0,
              onComplete: () => setIsRotating(false),
            },
          }
        }
      >
        <FnrIcon fas fa-redo />
      </Icon>
    </Button>
  );
}

const Button = styled(motion.button)`
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  &[disabled] {
    opacity: 0.5;
  }
`;
const Text = styled.div`
  margin-right: 4px;
`;
const Icon = styled(motion.div)`
  font-size: 10px;
`;
