import { bool, node } from "prop-types";
import { forwardRef, useMemo, useRef, useState } from "react";

import styled from "styled-components/macro";

import { motion, useAnimationFrame } from "framer-motion";

const Mover = styled(motion.div)`
  ${({ $clip }) => ($clip ? "overflow: clip;" : "")}
`;

const Static = styled("div")`
  ${({ $inline }) => ($inline ? "display: inline-flex;" : "")}
`;

/** A div that animates its properties when responding to changes in the content. */
const FnrMovingDiv = forwardRef(
  /**
   * The div itself is not visible. Put the wrapper of your choice around it.
   * @param {object} param0
   * @param {boolean} param0.heightMotion - responds to height changes with animation when true
   * @param {boolean} param0.widthMotion - responds to width changes with animation when true
   * @param {boolean} param0.clip - prevents children from leaving container.
   */
  (
    { children, heightMotion, widthMotion, clip = true, muted, ...rest },
    ref
  ) => {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const contentsRef = useRef();

    useAnimationFrame(() => {
      if (contentsRef.current?.clientHeight === undefined) return;
      if (contentsRef.current.clientHeight !== height && heightMotion)
        setHeight(contentsRef.current.clientHeight);

      if (contentsRef.current?.clientWidth === undefined) return;
      if (contentsRef.current.clientWidth !== width && widthMotion) {
        setWidth(contentsRef.current.clientWidth);
      }
    });

    const animate = useMemo(() => {
      let localAnimate = {};
      if (heightMotion) {
        localAnimate.height = height;
      }
      if (widthMotion) {
        localAnimate.width = width;
      }
      return localAnimate;
    }, [height, heightMotion, width, widthMotion]);

    return (
      <Mover
        animate={animate}
        transition={{ ease: "anticipate", duration: muted ? 0 : 0.3 }}
        data-cy="FnrMovingDiv"
        $clip={clip}
        ref={ref}
        {...rest}
      >
        <Static $inline={widthMotion} ref={contentsRef}>
          {children}
        </Static>
      </Mover>
    );
  }
);

FnrMovingDiv.propTypes = {
  heightMotion: bool,
  widthMotion: bool,
  children: node,
  clip: bool,
  muted: bool,
};

export default FnrMovingDiv;
