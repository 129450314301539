import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const SunNoRays = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 22.13">
    <FnrPath
      $virtue="hope"
      d="M34.14,19.5c0-.1,0-.2,0-.3.03-8.83-7.18-16.1-16.08-16.12-8.75-.02-16.12,6.93-16.14,16.09,0,.11,0,.22,0,.33h32.2Z"
    />
  </FnrSvg>
);
SunNoRays.propTypes = { size: number };
export default SunNoRays;
