import {
  arrayOf,
  bool,
  elementType,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { createContext, forwardRef, useMemo, useRef, useState } from "react";

import { useAnimationFrame } from "framer-motion";

import useScrollTo from "Utilities/Hooks/useScrollTo";

import MotionContainer from "./MotionContainer";

const FnrSlidesContext = createContext();

const FnrSlides = forwardRef(
  /**
   * @param {object} param0
   * @param {[FnrSlidesComponent]} param0.components
   * @param {string|number} param0.activeKey
   * @param {boolean} param0.fullWidth
   * @param {boolean} param0.hideHeightMotion
   * @param {boolean} param0.clip
   * @param {"standard"|"subtle"|"zoom"} param0.variant
   * @param {"x"|"y"} param0.axis
   * @param {import("react").Ref} passedInRef
   * @param {boolean} fullWidthForComponent - use this to pass fullWidth down to the component
   * @returns
   */
  (
    {
      components,
      activeKey = 0,
      fullWidth,
      hideHeightMotion,
      clip,
      variant = "standard",
      axis = "x",
      fullWidthForComponent,
      ...rest
    },
    passedInRef
  ) => {
    const localRef = useRef(null);
    const ref = passedInRef || localRef;
    const [currentWidth, setCurrentWidth] = useState(
      ref.current?.clientWidth || 0
    );

    useAnimationFrame(() => {
      if (currentWidth !== ref.current?.clientWidth) {
        setCurrentWidth(ref.current?.clientWidth);
      }
    });

    // if in a scrollable container, go to the top of it when changing slides
    useScrollTo({
      scrollElementRef: ref,
      scrollTrigger: activeKey,
      actOnParent: true,
    });

    const componentProps = useMemo(() => {
      return { ...rest, fullWidth: fullWidthForComponent };
    }, [fullWidthForComponent, rest]);

    return (
      <FnrSlidesContext.Provider
        value={{
          components,
          activeKey,
          fullWidth,
          variant,
          componentProps,
          hideHeightMotion,
          axis,
          containerRef: ref,
          currentWidth,
        }}
      >
        <MotionContainer ref={ref} clip={clip} />
      </FnrSlidesContext.Provider>
    );
  }
);

const fnrSlidesVariants = {
  standard: "standard",
  subtle: "subtle",
  zoom: "zoom",
};

FnrSlides.propTypes = {
  components: arrayOf(
    shape({ key: string.isRequired, value: elementType.isRequired })
  ).isRequired,
  activeKey: oneOfType([string, number]),
  fullWidth: bool,
  clip: bool,
  variant: oneOf(["standard", "subtle", "zoom"]),
  hideHeightMotion: bool,
  axis: oneOf(["x", "y"]),
  fullWidthForComponent: bool,
};

export { FnrSlidesContext, fnrSlidesVariants };
export default FnrSlides;
