import { getFundItStatus } from "./mockResponses/getFundItStatus";

const useMockData = false; // to mock API responses
const forceProgressState = false; // to force certain state values

// 🚨🚨🚨 👇 Don't change this 👇 🚨🚨🚨
const _useMockData_PROTECTED =
  process.env.NODE_ENV === "development" ? useMockData : false;
// const _useMockData_PROTECTED = useMockData; // since the endpoints don't work yet!
const _forceProgressState_PROTECTED =
  process.env.NODE_ENV === "development" ? forceProgressState : null;
// 🚨🚨🚨 👆 Don't change this 👆🚨🚨🚨

export const mockData = {
  shouldMock: _useMockData_PROTECTED,
  forceProgressState: _forceProgressState_PROTECTED,
  // mockSceenState: !forceScreen ? false : mockSceenState,
  getFundItStatus,
};

// const progressStates = [
//   "noPermissions",
//   "dashboard",
//   "missingDataForSignup",
//   "needTosAgreement",
//   "needToCreateFund",
//   "needToEnterBankInfo",
//   "needToEnterKYC",
//   "creatingAccount",
//   "hasKYCValidationError",
//   "waitingForKYCApproval",
// ];
