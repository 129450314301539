import { bool, node, string } from "prop-types";
import React, { createContext } from "react";

import { useTheme } from "styled-components";

import { HoverParent } from "UI/HoverChildren";

import { FnrPopover } from "./FnrPopover";

export const FnrSelectListContext = createContext();

export function FnrPopoverOptionsList({
  children,
  closeOnSelect,
  background = "ghostWhite",
  ...props
}) {
  const { colors } = useTheme();

  return (
    <FnrPopover {...props}>
      <HoverParent
        hoverStyle={{
          background: colors[background] || background,
          borderRadius: 6,
        }}
      >
        <FnrSelectListContext.Provider value={{ closeOnSelect }}>
          {children}
        </FnrSelectListContext.Provider>
      </HoverParent>
    </FnrPopover>
  );
}

FnrPopoverOptionsList.propTypes = {
  children: node,
  closeOnSelect: bool,
  background: string,
};
