import { useQuery } from "@tanstack/react-query";

import getSubdomain from "Utilities/getSubdomain";

import {
  getFundItStatus,
  getNetworkMetadata,
  getStaxStatus,
  getUserData,
  getUserFundItPermissions,
} from "./fetch";
import { flocknoteKeys } from "./keys";

export const useGetNetworkMetadata = () => {
  return useQuery({
    queryKey: flocknoteKeys.network(),
    queryFn: getNetworkMetadata,
    staleTime: Infinity,
    enabled: getSubdomain() !== "login", // there is no network to get metadata about here.
  });
};

export const useGetUserData = () => {
  return useQuery({
    queryKey: flocknoteKeys.user(),
    queryFn: getUserData,
    staleTime: Infinity,
  });
};

export const useGetUserFundItPermissions = () => {
  return useQuery({
    queryKey: flocknoteKeys.fundItPermissions(),
    queryFn: getUserFundItPermissions,
  });
};

export const useGetFundItStatus = () => {
  return useQuery({
    queryKey: flocknoteKeys.fundItStatus(),
    queryFn: getFundItStatus,
  });
};

export const useGetStaxStatus = () => {
  return useQuery({
    queryKey: flocknoteKeys.staxStatus(),
    queryFn: getStaxStatus,
  });
};
