import { bool, node, number, oneOf, oneOfType, string } from "prop-types";
import { forwardRef } from "react";

import styled from "styled-components/macro";

const SDiv = styled("div")`
  display: ${({ $inline }) => ($inline ? "inline-flex" : "flex")};
  flex-direction: ${({ $reverse }) => ($reverse ? "column-reverse" : "column")};
  ${({ $gap, $flexGrow }) => `
  ${$flexGrow ? "flex-grow: 1;" : ""}
  gap: ${$gap};`}
  ${({ $alignItems }) => ($alignItems ? `align-items: ${$alignItems};` : "")}
  transition: gap .3s;
`;

const FnrVerticalGap = forwardRef(
  /**
   * Creates a vertical gap
   * @param {object} param0
   * @param {number} param0.gap
   * @param {boolean} param0.inline
   * @param {number} param0.flexGrow
   * @param {boolean} param0.reverse
   * @param {"normal"|"center"|"end"} param0.alignItems
   * @param {import("react").Ref} ref
   * @returns
   */
  (
    {
      gap: gapArg = 16,
      inline,
      flexGrow,
      reverse,
      alignItems,
      children,
      ...rest
    },
    ref
  ) => {
    const gap = typeof gapArg === "number" ? `${gapArg}px` : gapArg;
    return (
      <SDiv
        $gap={gap}
        $inline={inline}
        $flexGrow={flexGrow}
        $reverse={reverse}
        $alignItems={alignItems}
        {...rest}
        ref={ref}
      >
        {children}
      </SDiv>
    );
  }
);

FnrVerticalGap.propTypes = {
  gap: oneOfType([number, string]),
  inline: bool,
  flexGrow: number,
  children: node,
  reverse: bool,
  alignItems: oneOf(["center", "normal", "end"]),
};

export default FnrVerticalGap;
