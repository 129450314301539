import FnrFetch from "Utilities/FnrFetch";

const devAPI = {
  loginWithEmailPassword: () => {
    return FnrFetch.mock({
      success: true,
      sessionID: "123456789",
      status: 200,
      requires2fa: true,
      contactInfo: "i********@gmail.com",
    });
  },
  sendTextCode: () => {
    return FnrFetch.mock({
      success: true,
      sessionID: 12341234,
      status: 200,
    });
  },
  loginWithTextCode: () => {
    return FnrFetch.mock({
      success: true,
      sessionID: 12341234,
      status: 200,
    });
  },
  sendEmailLoginLink: () => {
    return FnrFetch.mock({
      success: true,
      sessionID: 12341234,
      status: 200,
    });
  },
  instantlyAuthenticate: () => {
    return FnrFetch.mock({
      success: true,
      instantAuth: {
        success: true,
        isRequired: true,
        authCodeDestination: "234-234-2342",
        // authCode: '1234', // only for sending the test code with the request to the backend
      },
    });
  },
  loginWithTextCode2fa: () => {
    return FnrFetch.mock({
      success: true,
      sessionID: 12341234,
      status: 200,
    });
  },
  resend2fa: () => {
    return FnrFetch.mock({
      success: true,
      sessionID: 12341234,
      status: 200,
    });
  },
  genericEndpoint: () => {
    return FnrFetch.mock({
      success: true,
      instantAuth: {
        success: false,
        isRequired: true,
        authCodeDestination: "234-234-2342",
        // authCodeDestination: null, // if they don't have a verified mobile number
      },
    });
  },
};

export default devAPI;
