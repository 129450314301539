export const getSentryEnvironment = () => {
  const hostname =
    (window && window.location && window.location.hostname) || "";

  /*  
    NODE_ENV === "production" on all of our dev servers (since we do the React build on the server),
    so for dev servers we need to check the hostname to determine the environment.
  */

  if (process.env.NODE_ENV !== "production") {
    // handles "development" and "test"
    return process.env.NODE_ENV;
  } else if (hostname.includes("flocknote.com")) {
    return "production";
  } else if (hostname.includes("flocknotedev.internal")) {
    return "dev1";
  } else if (hostname.includes("flocknoteqa.internal")) {
    return "qa";
  } else if (hostname.includes("flocknotestaging.internal")) {
    return "staging";
  } else if (hostname.includes("flocknotedev2.internal")) {
    return "dev2";
  } else if (hostname.includes("flocknotedev3.internal")) {
    return "dev3";
  } else if (hostname.includes("flocknotedev4.internal")) {
    return "dev4";
  } else {
    return "production";
  }
};
