const { themeSizes } = require("theme/constants");

const sizes = {
  [themeSizes.xs]: 20,
  [themeSizes.sm]: 24,
  [themeSizes.md]: 28,
  [themeSizes.lg]: 32,
  [themeSizes.xl]: 40,
};

const square = ({ $size }) => `
  width: ${sizes[$size]}px;
  height: ${sizes[$size]}px;
`;

export default square;
