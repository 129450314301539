/**
 * @param {Element} ancestor
 * @param {Element} descendent
 * @returns
 */
const orderMatters = (ancestor, descendent) => {
  if (!descendent) return false;

  const { parentNode } = descendent;
  if (!parentNode) return false;
  if (parentNode === ancestor) return true;
  return orderMatters(ancestor, descendent.parentNode);
};

/**
 * @param {Element} node1
 * @param {Element} node2
 * @param {boolean} checkBoth - set to false to only check ancestry in one direction for better performance
 * @returns
 */
const isAncestorOf = (node1, node2, checkBoth = true) => {
  if (!node1 || !node2) return false;

  if (checkBoth) {
    return orderMatters(node1, node2) || orderMatters(node2, node1);
  }
  return orderMatters(node1, node2);
};

export default isAncestorOf;
