import React, { useContext, useState, useEffect } from "react";
import { FlocknoteAppState } from "Flocknote/state.js";

import "./FnrOverlay.scss";

export default function FnrOverlayBackdrop() {
  const [{ overlays }] = useContext(FlocknoteAppState);

  const [showBackdrop, setShowBackdrop] = useState(false);

  useEffect(() => {
    setShowBackdrop(
      overlays.length > 0 && overlays.find((ol) => ol.hideBackground)
        ? true
        : false
    );
  }, [overlays, overlays.length]);

  return (
    <div className={"FnrOverlayBackdrop " + (showBackdrop ? "show" : "")} />
  );
}
