import PropTypes from "prop-types";
import React from "react";

import styled from "styled-components/macro";

import { AnimatePresence, motion } from "framer-motion";

import loadingBricksGray from "UI/images/loading_bricks.gif";
import loadingBricksBlue from "UI/images/loading_bricks_blue.gif";

FnrLoadingBricks.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.oneOf(["blue", "gray"]),
};

FnrLoadingBricks.defaultProps = {
  size: 24,
  color: "blue",
};

export default function FnrLoadingBricks({ color, show, size, style }) {
  const getImgSrc = () => {
    switch (color) {
      case "gray":
        return loadingBricksGray;
      case "blue":
      default:
        return loadingBricksBlue;
    }
  };

  return (
    <AnimatePresence>
      {show ? (
        <Wrapper
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          style={style}
        >
          <Img src={getImgSrc()} size={size} alt="loading" />
        </Wrapper>
      ) : null}
    </AnimatePresence>
  );
}

const Img = styled.img`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  display: block;
`;

const Wrapper = styled(motion.div)`
  display: inline-block;
`;
