import { number } from "prop-types";

import FnrRect from "../components/FnrRect";
import FnrSvg from "../components/FnrSvg";

const SquareFilled = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 35.28">
    <FnrRect
      $virtue="courage"
      class="cls-1"
      x="2.38"
      y="1.98"
      width="31.32"
      height="31.32"
      rx="1.36"
      ry="1.36"
    />
  </FnrSvg>
);

SquareFilled.propTypes = { size: number };

export default SquareFilled;
