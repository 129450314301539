export function createUrl(endpoint) {
  // for production environment, no need to specify hostname... just return the endpoint.
  if (process.env.NODE_ENV !== "development") return endpoint;

  // if running via localhost:3000, look for hostname value in .env or localstorage
  const isLocalhost = window.location.hostname.includes("localhost");
  if (isLocalhost) {
    // Dev URL Option 1: set a variable in .env.local called "REACT_APP_DEV_API_URL"
    if (process.env.REACT_APP_DEV_API_URL)
      return process.env.REACT_APP_DEV_API_URL + endpoint;

    // Dev URL Option 2: use https://localhost:3000/dev to set the endpoint
    if (localStorage.getItem("devEndpoint"))
      return "https://" + localStorage.getItem("devEndpoint") + endpoint;

    return "https://testnet.flocknote.docker" + endpoint;
  }

  // other dev environments (dev container, etc) also do not need hostname specified.
  return endpoint;
}
