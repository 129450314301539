import { themeSizes } from "theme/constants";

const toDec = {
  [themeSizes.xs]: ".5",
  [themeSizes.sm]: ".75",
  [themeSizes.md]: "1",
  [themeSizes.lg]: "1.25",
  [themeSizes.xl]: "1.5",
};

/**
 *
 * @param {object} param0
 * @param {'xs'|'sm'|'md'|'lg'|'xl'} param0.$scale
 * @returns
 */
const sizeIt = ({ $scale }) => {
  return `transform: scale(${
    toDec[$scale] || $scale || toDec[themeSizes.md]
  });`;
};

export default sizeIt;
