import React, { useContext, useEffect } from "react";

import { FnrPopoverContext } from "./Wrapper";

export default function FnrPopoverTriggerButton({
  children,
  forceRefForMeasurement,
  disabled,
  ...rest
}) {
  const [{ show, buttonRef }, dispatch] = useContext(FnrPopoverContext);

  useEffect(() => {
    if (!forceRefForMeasurement) return;

    dispatch({ type: "Force Button Ref Added", ref: forceRefForMeasurement });

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [forceRefForMeasurement]);

  const toggleShow = (event, info) => {
    if (!show) dispatch({ type: "Show Popover" });
    else dispatch({ type: "Hide Popover" });
  };

  return (
    <div ref={buttonRef} onClick={disabled ? null : toggleShow} {...rest}>
      {children}
    </div>
  );
}
