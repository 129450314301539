import { getAmountInDollarsClean } from "Utilities/Amount/getAmountInDollarsClean";

export function convertSignupListItemAmountsToDollars(signupList) {
  signupList?.signupItems?.forEach((item, i) => {
    if (!item.amount) return;
    let dollarAmount = getAmountInDollarsClean(item.amount);
    signupList.signupItems[i].amount = dollarAmount;
  });

  return signupList;
}
