import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { setupFnReactBusAppState } from "Utilities/FnrBoardTheBus";
import { FlocknoteAppState } from "./state.js";

export default function BoardTheBus() {
  const [state, dispatch] = useContext(FlocknoteAppState);
  let routerHistory = useHistory();

  useEffect(() => {
    setupFnReactBusAppState(state, dispatch, routerHistory);
  }, [state, dispatch, routerHistory]);

  return null;
}
