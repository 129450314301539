import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import styled from "styled-components/macro";

import { useFlocknoteAppState } from "Flocknote/state";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import API from "Login/API";
import ErrorMessage from "Login/ErrorMessage";
import InstantAuthTextCodeInput from "Login/InstantAuth/InstantAuthTextCodeInput";
import logo from "Login/logo-white.png";
import { motion, useAnimation } from "framer-motion";

import ResendCodeButton from "./ResendCodeButton";
import SentResent from "./SentResent";

export default function InstantAuthContent() {
  const [
    {
      instantAuth: { phoneNumber, route, postData, message, hideCloseButton },
    },
    flocknoteAppDispatch,
  ] = useFlocknoteAppState();

  const [textCode, setTextCode] = useState("");
  const [errorCode, setErrorCode] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [sendingTextCode, setSendingTextCode] = useState(false);
  const [codeResendCount, setCodeResendCount] = useState(0);

  const controls = useAnimation();

  const handleResendCode = async () => {
    setSendingTextCode(true);
    setSubmitting(true);

    try {
      await API.genericEndpoint(route, postData).post();
      return;
      /**
       * This is weird, but this is how this is working
       * Resending to the endpoint will return an object with success: false
       * On the backend, that is part of what triggers sending a text code
       * API.genericEndpoint uses FnrFetch3, which rejects when success: false is returned
       * So we actually expect the response here to come back in the catch response
       * So there isn't anything to be done here inside the try brackets
       *
       **/
    } catch (err) {
      if (!err.success && err.instantAuth.isRequired) {
        setCodeResendCount((count) => count + 1);
      } else {
        setErrorCode("sendingTextCodeFailed");
      }
    }

    setSendingTextCode(false);
    setSubmitting(false);
  };

  useEffect(() => {
    if (phoneNumber === null) {
      setErrorCode("noVerifiedPhoneNumber");
    }
  }, [phoneNumber, setErrorCode]);

  useEffect(() => {
    if (submitting && sendingTextCode) {
      controls.start(() => ({
        rotate: 360,
        transition: {
          duration: 1.5,
          ease: "easeOut",
          repeat: 2,
        },
      }));
    }
  }, [submitting, sendingTextCode, controls]);

  return (
    <LoginWrapper
      key="instantAuthForm"
      initial={{ scale: 0, opacity: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{
        opacity: 0,
        scale: 0.2,
        transition: { type: "tween", ease: "easeIn", duration: 0.25 },
      }}
    >
      <TextLoginContainer>
        <Logo
        // href="https://flocknote.com"
        // target="_blank"
        // alt="Visit Flocknote's website"
        // whileTap={{ scale: 0.9 }}
        >
          {/* Flocknote */}
        </Logo>

        {message && message.length > 0 ? <Message>{message}</Message> : null}

        <div style={{ textAlign: "center", fontSize: "20px" }}>
          <div>
            Enter the code we <SentResent codeResendCount={codeResendCount} />{" "}
            to{" "}
          </div>
          <PhoneNumber>{phoneNumber}</PhoneNumber>
          {/* email needs a div around it on smaller screens to force it to wrap, but not on larger screens. */}
        </div>

        <InstantAuthTextCodeInput
          textCode={textCode}
          setTextCode={setTextCode}
          sendingTextCode={sendingTextCode}
          errorCode={errorCode}
          setErrorCode={setErrorCode}
          submitting={submitting}
          setSubmitting={setSubmitting}
        />

        <ErrorMessage errorCode={errorCode} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          {!hideCloseButton ? (
            <NoStyleButton
              onClick={() => {
                // setShowTextCodeInput(false);
                setTextCode("");
                setErrorCode(null);
                setSubmitting(false);
                flocknoteAppDispatch({ type: "Hide Instant Auth" });
              }}
              disabled={submitting}
              whileHover={!submitting && { scale: 1.05 }}
              whileTap={!submitting && { scale: 0.95 }}
            >
              <FontAwesomeIcon icon={faTimes} /> <Text>Close</Text>
            </NoStyleButton>
          ) : null}
          {/* TODO: Replace this with FnrResendCode */}
          <ResendCodeButton
            onClick={handleResendCode}
            isLoading={submitting && sendingTextCode}
            disabled={submitting}
          />
        </div>
      </TextLoginContainer>
    </LoginWrapper>
  );
}

export const LoginWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding: 30px 10px;
  width: 340px;
  box-sizing: border-box;
  border-radius: 49px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 12px;
  background: linear-gradient(to bottom right, #016099, #13426b);
  color: #eceff0;

  @media (max-width: 360px) {
    width: min(340px, calc(100vw - 20px));
  }
`;

const PhoneNumber = styled.div`
  font-weight: bold;
`;

export const TextLoginContainer = styled.div`
  width: 280px;

  @media (max-width: 360px) {
    width: 260px;
  }
`;

export const NoStyleButton = styled(motion.button)`
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  &[disabled] {
    opacity: 0.5;
  }
`;

const Text = styled.span`
  margin-left: 4px;
`;

export const Logo = styled.div`
  display: block;
  padding-bottom: 22%;
  background-image: url(${logo});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 220px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0) !important;
  text-indent: -5000px;
`;

const Message = styled.div`
  text-align: center;
  font-size: 17px;
  margin: 14px 0 17px 0;
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 11px;
  padding: 3px;
`;
