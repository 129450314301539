import { object, oneOf } from "prop-types";
import { useState } from "react";

import { useAnimationFrame } from "framer-motion";

import FundItLogo from "UI/images/FundItLogo";
import UnoLogo from "UI/images/UnoLogo";
import CircleFilled from "UI/images/virtueIcons/CircleFilled";
import CircleOutline from "UI/images/virtueIcons/CircleOutline";
import DottedLine from "UI/images/virtueIcons/DottedLine";
import Heart from "UI/images/virtueIcons/Heart";
import Plus from "UI/images/virtueIcons/Plus";
import SqigglyLine from "UI/images/virtueIcons/SqigglyLine";
import Square from "UI/images/virtueIcons/Square";
import SquareFilled from "UI/images/virtueIcons/SquareFilled";
import Sun from "UI/images/virtueIcons/Sun";
import SunNoRays from "UI/images/virtueIcons/SunNoRays";
import TriangleFilled from "UI/images/virtueIcons/TriangleFilled";
import TriangleOutline from "UI/images/virtueIcons/TriangleOutline";
import UpArrow from "UI/images/virtueIcons/UpArrow";

const customIconKeys = {
  fundIt: "fundIt",
  uno: "uno",
  veracity: "veracity",
  "veracity far": "veracity far",
  hope: "hope",
  "hope rays": "hope rays",
  wisdom: "wisdom",
  justice: "justice",
  humility: "humility",
  "humility far": "humility far",
  courage: "courage",
  "courage far": "courage far",
  temperance: "temperance",
  patience: "patience",
  love: "love",
  faith: "faith",
};

const customIcons = {
  [customIconKeys.fundIt]: FundItLogo,
  [customIconKeys.uno]: UnoLogo,
  [customIconKeys.veracity]: TriangleFilled,
  [customIconKeys["veracity far"]]: TriangleOutline,
  [customIconKeys.hope]: SunNoRays,
  [customIconKeys["hope rays"]]: Sun,
  [customIconKeys.wisdom]: UpArrow,
  [customIconKeys.justice]: UpArrow, // rotated 90 degrees
  [customIconKeys.humility]: CircleFilled,
  [customIconKeys["humility far"]]: CircleOutline,
  [customIconKeys.courage]: Square,
  [customIconKeys["courage far"]]: SquareFilled,
  [customIconKeys.temperance]: SqigglyLine,
  [customIconKeys.patience]: DottedLine,
  [customIconKeys.love]: Heart,
  [customIconKeys.faith]: Plus,
};

/**
 * Designed to respect font size and color of context, just like a fa icon.
 * @param {object} param0
 * @param {import("react").Ref} param0.containerRef
 */
const CustomIcon = ({ containerRef, icon }) => {
  const [fontSizeState, setFontSize] = useState("");
  const [colorState, setColor] = useState("");

  const Icon = customIcons[icon];

  const rotation = icon === customIconKeys.justice ? 90 : undefined;

  useAnimationFrame(() => {
    const { fontSize, color } = containerRef.current
      ? getComputedStyle(containerRef.current)
      : {};
    if (fontSize !== fontSizeState) setFontSize(fontSize);
    if (color !== colorState) setColor(color);
  });
  return (
    <Icon
      rotation={rotation}
      size={Number(fontSizeState.replace("px", ""))}
      color={colorState}
    />
  );
};

CustomIcon.propTypes = {
  containerRef: object,
  icon: oneOf(Object.keys(customIcons)).isRequired,
};

export { customIconKeys };
export default CustomIcon;
