import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components/macro";

import { AnimatePresence, motion } from "framer-motion";

import { HoverChild } from "UI/HoverChildren";
import loadingBricksGray from "UI/images/loading_bricks.gif";

import useComponentIsMounted from "Utilities/Hooks/useComponentIsMounted";

import { FnrPopoverStateContext } from "./FnrPopover";
import { FnrSelectListContext } from "./OptionsList";

export const FnrPopoverOption = forwardRef(
  (
    {
      children,
      disabled = false,
      selected = false,
      loading = false,
      onClick,
      forceHover = false,
      ...props
    },
    forwardedRef
  ) => {
    const { closeOnSelect } = useContext(FnrSelectListContext);
    const popoverState = useContext(FnrPopoverStateContext);

    const [tapOrigins, setTapOrigins] = useState({});

    const mounted = useComponentIsMounted();

    useEffect(() => {
      if (mounted && selected && closeOnSelect) {
        const id = setTimeout(() => popoverState?.onShouldClose(), 300);
        return () => clearTimeout(id);
      }

      // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
      // eslint-disable-next-line
    }, [selected]);

    const tapRef = useRef();

    const tapStarted = (event, info) => {
      const measurements = tapRef.current.getBoundingClientRect();

      const relativeLeft = info.point.x - measurements.left;
      const relativeTop = info.point.y - measurements.top;

      setTapOrigins({
        originX: Math.min(
          Math.max(
            0.2,
            relativeLeft <= 0
              ? 0
              : relativeLeft >= measurements.width
              ? 1
              : relativeLeft / measurements.width
          ),
          0.8
        ),
        originY: Math.min(
          Math.max(
            0.2,
            relativeTop <= 0
              ? 0
              : relativeTop >= measurements.height
              ? 1
              : relativeTop / measurements.height
          ),
          0.8
        ),
      });
    };

    return (
      <Wrapper
        disabled={disabled}
        onClick={disabled ? null : onClick}
        {...props}
        ref={forwardedRef}
      >
        <HoverChild disabled={disabled} forceHover={forceHover}>
          <Inside
            whileTap={disabled ? null : { scale: 0.9 }}
            style={tapOrigins}
            onTapStart={tapStarted}
            ref={tapRef}
            data-element="fnr-popover-option"
          >
            {children}
            <AnimatePresence>
              {!loading && selected && <Selected key="selected" />}
              {loading && <Loading key="loading" />}
            </AnimatePresence>
          </Inside>
        </HoverChild>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  font-size: 18px;
  font-weight: 400;
  border-radius: 6px;
  margin-bottom: 1px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? ".6" : "1")};
  position: relative;
  z-index: 100;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Inside = styled(motion.div)`
  padding: 6px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-right: 42px;
  position: relative;
`;

export function FnrPopoverOptionIcon({ icon }) {
  return <Icon className={"fa-" + icon} />;
}

const Icon = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
  width: 18px;
  text-align: center;
`;

const Selected = () => {
  return (
    <SelectedCheck
      initial={{ opacity: 0, scale: 0.5, y: "-50%" }}
      animate={{ opacity: 1, scale: 1, y: "-50%" }}
      exit={{
        opacity: 0,
        scale: 0.5,
        y: "-50%",
        transition: {
          type: "tween",
          duration: 0.2,
          ease: "backIn",
        },
      }}
      className="fas fa-check"
    ></SelectedCheck>
  );
};

const SelectedCheck = styled(motion.div)`
  width: 18px;
  position: absolute;
  right: 10px;
  top: 50%;
  height: 18px;
`;

const Loading = () => {
  return (
    <SelectedCheck
      initial={{ opacity: 0, scale: 0.5, y: "-50%" }}
      animate={{ opacity: 1, scale: 1, y: "-50%" }}
      exit={{
        opacity: 0,
        scale: 0.5,
        y: "-50%",
        transition: {
          type: "tween",
          duration: 0.2,
          ease: "backIn",
        },
      }}
    >
      <LoadingImage src={loadingBricksGray} />
    </SelectedCheck>
  );
};

const LoadingImage = styled.img`
  width: 18px;
  height: 18px;
`;
