/**
 * indents the element by 16px using margin-left
 * @param {object} param0
 * @param {boolean} param0.$noIndent - turns off the indent
 * @returns
 */
const indented = ({ $noIndent, $variant }) => {
  if ($noIndent) return "";
  switch ($variant) {
    case "info":
    case "draggable":
    case "icon":
      return `margin: 0px 4px;`;
    default:
      return `margin: 0px 8px;`;
  }
};

export default indented;
