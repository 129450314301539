import Drawer from "./Drawer";
import Modal from "./Modal";

const GlobalComponents = () => (
  <>
    {/* Add any components here that should always be rendered. */}
    <Modal />
    <Drawer />
  </>
);

export default GlobalComponents;
