import { colorVariants, themeVariants } from "theme/constants";

const border = ({
  $variant,
  $dark,
  $colorVariant,
  $color = "divider",
  $thick = 1, // boolean or number
  theme: { colors, palette },
  $noBorder,
}) => {
  if ($noBorder) return "";
  const { transparent } = colors;
  const thickness = $thick === true ? 3 : $thick;
  const color = palette[$color] || colors[$color] || $color;
  switch ($colorVariant) {
    case colorVariants.light:
      return `border: ${thickness}px solid ${color};`;
    default: //continue
  }

  switch ($variant) {
    case themeVariants.accordion:
      if ($dark) return `border: ${thickness}px solid ${color};`;
      return `border: 0px solid ${transparent};`;
    case "hidden":
      return `border: 0px solid ${transparent};`;
    default:
      return `border: ${thickness}px solid ${color};`;
  }
};

export default border;
