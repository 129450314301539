import { themeSizes, widthBySize } from "theme/constants";

const fontSize = ({ $variant, $size, theme: { fontSizes }, $noPadding }) => {
  switch ($variant) {
    case "icon": {
      const minWidth = widthBySize[$size || themeSizes.md];
      switch ($size) {
        case themeSizes.decorative1:
        case themeSizes.decorative2:
        case themeSizes.decorative3:
        case themeSizes.xl:
        case themeSizes.lg:
        case themeSizes.sm:
        case themeSizes.xs:
          if ($noPadding)
            return `font-size: ${fontSizes[$size]}; min-width: 0px;`;
          return `font-size: ${fontSizes[$size]}; min-width: ${minWidth}px;`;
        case themeSizes.md:
        default:
          if ($noPadding) return "";
          return `min-width: ${minWidth}px;`;
      }
    }
    case "info":
      return `font-size: ${fontSizes[$size || "sm"]};`;
    default:
      switch ($size) {
        case themeSizes.xl:
          return `font-size: ${fontSizes[$size]};`;
        case themeSizes.lg:
          return `font-size: ${fontSizes[$size]};`;
        case themeSizes.sm:
          return `font-size: ${fontSizes[$size]};`;
        case themeSizes.xs:
          return `font-size: ${fontSizes[$size]};`;
        case themeSizes.md:
        default:
          return `font-size: ${fontSizes.md};`;
      }
  }
};

export default fontSize;
