import { colorVariants, themeVariants } from "theme/constants";

const backgroundColor = ({
  $variant,
  $dark,
  $addGradient,
  $noBackgroundColor,
  $colorVariant,
  $backgroundColor,
  $backgroundBlur,
  theme,
}) => {
  const { colors, palette } = theme;
  const { transparent, fnDarkerBlue, fnDarkBlueGrey, white, lightGray } =
    colors;

  if ($noBackgroundColor) return "";
  if ($backgroundBlur) return `background-color: ${transparent};`;
  if ($backgroundColor) {
    return `background-color: ${
      colors[$backgroundColor] || palette[$backgroundColor] || $backgroundColor
    };`;
  }
  switch ($colorVariant) {
    case colorVariants.dark:
      return `background-color: ${fnDarkerBlue};`;
    case colorVariants.popIt:
      return `background-color: ${fnDarkBlueGrey};`;
    case colorVariants.light:
      return `background-color: ${white};`;
    default: //continue
  }
  if ($addGradient) return "";
  switch ($variant) {
    case themeVariants.emphasized:
      return `background-color: ${lightGray};`;
    case "badge":
      return `background-color: ${fnDarkerBlue};`;
    case "dark":
      return `background-color: ${fnDarkBlueGrey};`;
    case "container":
    case "input":
    case "modal":
      if ($dark) return `background-color: ${fnDarkBlueGrey};`;
      return `background-color: ${white};`;
    case "accordion":
      if ($dark) return `background-color: ${fnDarkBlueGrey};`;
      return `background-color: ${lightGray};`;
    default:
      return `background-color: ${transparent};`;
  }
};

export default backgroundColor;
