import PropTypes from "prop-types";
import { useEffect } from "react";

PropTypes.useScrollTo = {
  scrollElementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  scrollTrigger: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  top: PropTypes.number,
};

/**
 *
 * @param {object} param0
 * @param {import("react").Ref} param0.scrollElementRef
 * @param {any} param0.scrollTrigger
 * @param {number} param0.top
 * @param {boolean} param0.actOnParent - set to true to scroll the parentNode of the ref
 * @returns
 */
export default function useScrollTo({
  scrollElementRef,
  scrollTrigger,
  top = 0,
  actOnParent,
}) {
  useEffect(() => {
    const node = actOnParent
      ? scrollElementRef?.current?.parentNode
      : scrollElementRef?.current;

    if (typeof node?.scrollTo === "function") {
      node.scrollTo({
        top: top,
        behavior: "smooth",
      });
    }
  }, [scrollTrigger, scrollElementRef, top, actOnParent]);

  return null;
}
