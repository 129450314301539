const getInnerHeight = (elem) => {
  if (!elem) return 0;
  const {
    style: { paddingTop, paddingBottom },
    clientHeight,
  } = elem;

  const padding =
    Number(paddingTop.replace("px", "")) +
    Number(paddingBottom.replace("px", ""));

  return clientHeight - padding;
};

export default getInnerHeight;
