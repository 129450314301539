export const FnrPopoverReducer = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case "Show Popover":
      newState.show = true;
      return newState;

    case "Hide Popover":
      newState.show = false;
      return newState;

    case "Force Button Ref Added":
      newState.forceButtonRefForMeasurement = action.ref;
      return newState;

    default:
      return newState;
  }
};
