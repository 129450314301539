const selected = ({
  $selected,
  $disabled,
  theme: {
    colors: { fnBlue, fnDarkBlue, textLight, fnDarkGrey, divider },
  },
}) =>
  $selected
    ? `
        background: linear-gradient(to right bottom, ${
          $disabled ? fnDarkGrey : fnBlue
        }, ${$disabled ? divider : fnDarkBlue});
        color: ${textLight};
      `
    : "";

export default selected;
