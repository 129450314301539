import { useFlocknoteAppState } from "Flocknote/state";

import MissingNetworkInfoModal from "CrossFeatureComponents/MissingNetworkInfoModal/MissingNetworkInfoModal";

export default function MissingNetworkInfo() {
  const [
    {
      missingNetworkInfoModal: { show, data },
    },
    dispatch,
  ] = useFlocknoteAppState();

  const subTitle =
    "We need to collect more information about your organization before you can send a note. This information is needed to stay up-to-date with new communication policies from phone carriers.";
  const networkRequiredFields = ["hasAddress", "hasWebsite"];
  const userRequiredFields = [
    "hasSuperAdminContact",
    "userEmailVerified",
    "userPhoneVerified",
    "userHasFirstName",
    "userHasLastName",
  ];

  const handleClose = () => {
    dispatch({
      type: "Show Missing Network Info Modal",
      show: false,
    });
  };

  const handleBypass = () => {
    if (data.newNoteType === "sms") {
      window.fnReactBus.openTextComposer(true);
    } else {
      window.fnReactBus.openEmailComposer(true);
    }
    handleClose();
  };

  if (!show) return null;

  return (
    <MissingNetworkInfoModal
      show={show}
      data={data}
      userID={data?.userID || null}
      onClose={handleClose}
      onContinue={handleBypass}
      subTitle={subTitle}
      networkRequiredDataFields={networkRequiredFields}
      userRequiredDataFields={userRequiredFields}
      continueButtonText="Remind me later"
    />
  );
}
