import { bool, node, number, object } from "prop-types";
import { useRef, useState } from "react";

import styled from "styled-components";
import { height, themeVariants } from "theme";
import { MAX_MODAL_PX_HEIGHT, MAX_MODAL_VH } from "theme/interpolations/height";

import { useAnimationFrame } from "framer-motion";

import FnrVerticalGap from "UI/commonContainers/FnrVerticalGap";

import getInnerHeight from "Utilities/elementFunctions/getInnerHeight";

const SFnrVerticalGap = styled(FnrVerticalGap)`
  ${height}
`;

/**
 * A scrollable container with min/max height with padding that just works. Content is taken to the edge instead of padding cutting it off. Height of containerRef's element must be a source of truth for the height.
 * @param {object} param0
 * @param {number} param0.maxVh - StaticHeight only. The tallest it should be for short screens. Should correspond to container.
 * @param {number} param0.maxPx - StaticHeight only. The tallest it should be for tall screens. Should correspond to container.
 * @param {number} param0.paddingY - StaticHeight only. The amount of padding at the top and bottom of the scrollable area. Should correspond to container.
 * @param {boolean} param0.staticHeight - Forces height to passed in maxVh and maxPx. Defaults to modal settings.
 * @param {import("react").Ref} param0.containerRef - The ref controlling the height
 */
const FnrDynamicHeight = ({
  maxVh = MAX_MODAL_VH, // redundant to height interpolation default
  maxPx = MAX_MODAL_PX_HEIGHT, // redundant to height interpolation default
  paddingY = 0,
  staticHeight,
  containerRef,
  children,
  ...rest
}) => {
  const [noHeight, setNoHeight] = useState();
  const ref = useRef();

  useAnimationFrame(() => {
    const newValue =
      ref?.current?.scrollHeight + (staticHeight ? paddingY * 2 : 0) - 4 > // TODO: find out why -4 and add this value dynamically.
      getInnerHeight(containerRef?.current);
    if (newValue !== noHeight) setNoHeight(newValue);
  });

  return (
    <SFnrVerticalGap
      $variant={staticHeight ? themeVariants.contents : undefined}
      $maxVh={maxVh}
      $maxPx={maxPx}
      $yAdjust={paddingY * 2}
      $noHeight={noHeight}
      ref={ref}
      data-cy="FnrDynamicHeight_Container"
      {...rest}
    >
      {children}
    </SFnrVerticalGap>
  );
};
FnrDynamicHeight.propTypes = {
  maxVh: number,
  maxPx: number,
  paddingY: number,
  containerRef: object,
  staticHeight: bool,
  children: node,
};

export default FnrDynamicHeight;
