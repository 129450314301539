import { useLocation } from "react-router-dom";

/*
As the name suggests, this is only used/rendered when the app is in development mode (i.e. using via localhost:300).
It inserts the necessary HTML structure from the flocknote web app so that the proper elements are in place for React
to portal into.
*/

// The dark left-hand bar/menu area
const networkLeftHTML = `
  <div
  id="network_left"
  style="
    width: 340px;
    flex-grow: 0;
    flex-shrink: 0;
    background: #29373e;
    position: relative;
    z-index: 3;
  "
  ></div>
`;

const signupsHTML = `
  <div
    class="page_network_signups on"
    style="flex-grow: 1; position: relative; z-index: 2;"
  ></div>
`;

// Includes top placeholder for group name/tabs area
const groupHTML = `
  <div
    class="page_group on"
    style="
      flex-grow: 1;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
    "
  >
    <div
      class="page_group_top"
      style="
        height: 80px;
        border-bottom: 1px solid #98a4a5;
        flex-grow: 0;
        flex-shrink: 0;
      "
    ></div>
    <div
      class="group_content"
      style="flex-grow: 1; overflow:hidden; position: relative;"
    ></div>
  </div> 
`;

const userProfileHTML = `
  <div style="width: 500px;">
    <div class="user_profile_pending_merge_react_holder"></div>
  </div>
`;

const dashboardHTML = `
  <div style="margin: 40px; display: flex;">
    <div id="dashboard_main_content" style="width: 548px; margin-right: 20px;"></div>
    <div id="dashboard_right_content" style="width: 280px"></div>
  </div>
`;

export default function HandleDevEnvPageStructure() {
  const { pathname } = useLocation();

  // For Dev environment only!
  if (process.env.NODE_ENV !== "development") return null;

  const networkMockDiv = document.querySelector("#network_mock");

  // some dev-only pages remove the newtorkMockDiv internally (such as the UI Kit pages)
  if (!networkMockDiv) return null;

  const isSinglePage =
    pathname.startsWith("/signup/") ||
    pathname.startsWith("/magicnotes/") ||
    pathname.startsWith("/merge");

  const isSignupsPage = pathname.startsWith("/signups");
  const isUserProfile = pathname.includes("/user");
  const isDashboardPage = pathname.startsWith("/dashboard");

  if (isSinglePage) {
    networkMockDiv.remove();
  } else if (isSignupsPage) {
    networkMockDiv.innerHTML = networkLeftHTML + signupsHTML;
  } else if (isUserProfile) {
    networkMockDiv.innerHTML = networkLeftHTML + userProfileHTML;
  } else if (isDashboardPage) {
    networkMockDiv.innerHTML = networkLeftHTML + dashboardHTML;
  } else {
    networkMockDiv.innerHTML = networkLeftHTML + groupHTML;
  }

  return null;
}
