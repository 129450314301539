export default function fnReactBoardTheBus(
  ComponentName,
  loaderFunction,
  runningTimer = 0
) {
  if (runningTimer >= 60000) return; //alert("Something isn't loading right");

  if (window.fnReactBus)
    window.fnReactBus.registerComponentLoader(ComponentName, loaderFunction);
  else
    setTimeout(
      () =>
        fnReactBoardTheBus(
          ComponentName,
          loaderFunction,
          (runningTimer += 100)
        ),
      100
    );
}

export function setupFnReactBusAppState(
  state,
  dispatch,
  history,
  runningTimer = 0
) {
  if (runningTimer >= 60000) return; //alert("Something isn't loading right");

  if (window.fnReactBus) {
    window.fnReactBus.getState = () => {
      return { ...state };
    };
    window.fnReactBus.setState = (key, value) =>
      dispatch({ type: "fn_key_value", key: key, value: value });
    window.fnReactBus.dispatch = (type, data) => {
      if (typeof data != "object") data = { value: data };
      dispatch({ type: type, ...data });
    };
    window.fnReactBus.history = history;
  } else
    setTimeout(
      () =>
        setupFnReactBusAppState(
          state,
          dispatch,
          history,
          (runningTimer += 100)
        ),
      100
    );
}
