import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const CircleOutline = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 35.28">
    <FnrPath
      $virtue="humility"
      d="M18.04,4.99c6.97,0,12.65,5.67,12.65,12.65s-5.67,12.65-12.65,12.65-12.65-5.67-12.65-12.65,5.67-12.65,12.65-12.65M18.04,1.6C9.18,1.6,2,8.78,2,17.64s7.18,16.04,16.04,16.04,16.04-7.18,16.04-16.04S26.9,1.6,18.04,1.6h0Z"
    />
  </FnrSvg>
);

CircleOutline.propTypes = { size: number };

export default CircleOutline;
