import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const UpArrow = ({ size, rotation }) => (
  <FnrSvg size={size} rotation={rotation} viewBox="0 0 36.08 23.06">
    <FnrPath
      $virtue="wisdom"
      d="M32.14,20.84c-.58,0-1.16-.22-1.6-.66l-12.5-12.5-12.5,12.5c-.88.89-2.32.89-3.2,0-.88-.88-.88-2.32,0-3.2L16.44,2.88c.88-.89,2.32-.89,3.2,0l14.1,14.1c.88.88.88,2.32,0,3.2-.44.44-1.02.66-1.6.66Z"
    />
  </FnrSvg>
);

UpArrow.propTypes = { size: number, rotation: number };

export default UpArrow;
