import { bool, func, node, oneOf, string } from "prop-types";
import { forwardRef } from "react";

import styled from "styled-components/macro";
import {
  backgroundColor,
  border,
  borderRadius,
  marginBottom,
  padding,
  shadow,
} from "theme";
import overflow from "theme/interpolations/overflow";

import fnrCardVariants from "./fnrCardVariants";

const clickableStyles = ({
  $clickable,
  $addGradient,
  $disabled,
  theme: {
    colors: { offWhite, white },
  },
}) =>
  $clickable
    ? `${
        $addGradient
          ? `background-image: linear-gradient(to left top, ${offWhite}, ${white});`
          : ""
      }
      cursor: ${$disabled ? " not-allowed" : "pointer"};
    `
    : "";

const FnrCardContainer = styled("div")`
  ${padding}
  ${borderRadius}
  ${border}
  ${backgroundColor}
  ${clickableStyles}
  ${marginBottom}
  ${overflow}
  ${shadow}
  transition: border 0.05s, background-color 0.3s, padding 0.3s,
    border-radius 0.3s, margin 0.3s;
`;

const FnrCard = forwardRef(
  /**
   * A standard FlockNote styled container with padding, border, etc.
   * @param {object} param0
   * @param {function} param0.onClick
   * @param {boolean} param0.noGradient - turn off the gradiant, even when there is a onClick
   * @param {"container"|"input"|"modal"|"accordion"|"hidden"} param0.variant - a string passed down theme interpolations.
   * @param {boolean} param0.dark - inverts color scheme to be dark.
   * @param {boolean} param0.contentEmphasized - if the top and bottom conten is using the emphasized variant, this will correct your y-padding.
   * @param {boolean} param0.clip - restricts content from leaking out of the card using overflow.js.
   * @param {string} param0.colorVariant - overRides default colors.
   * @param {import("react").RefObject} ref
   */
  (
    {
      onClick,
      noGradient,
      variant = "container",
      children,
      disabled,
      dark,
      contentEmphasized,
      clip,
      colorVariant,
      shadowVariant,
      ...rest
    },
    ref
  ) => {
    const handleClick = () => {
      if (disabled) return;
      if (onClick) onClick();
    };

    return (
      <FnrCardContainer
        ref={ref}
        onClick={handleClick}
        $clickable={Boolean(onClick)}
        $addGradient={Boolean(onClick) && !noGradient}
        $variant={variant}
        $disabled={disabled}
        $dark={dark}
        $contentEmphasized={contentEmphasized}
        $clip={clip}
        $colorVariant={colorVariant}
        data-cy="FnrCard_Container"
        role="group"
        $noShadow={Boolean(!shadowVariant)}
        $shadowVariant={shadowVariant}
        {...rest}
      >
        {children}
      </FnrCardContainer>
    );
  }
);

FnrCard.propTypes = {
  onClick: func,
  /** Clickable cards have a gradiant added. This will remove that. */
  noGradient: bool,
  /** Controls the $variant prop passed down to interpolations */
  variant: oneOf(Object.values(fnrCardVariants)),
  disabled: bool,
  children: node,
  dark: bool,
  contentEmphasized: bool,
  clip: bool,
  colorVariant: string,
  shadowVariant: string,
};

export default FnrCard;
