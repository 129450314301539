const overflow = ({ $clip }) => {
  if ($clip)
    return `
        position: relative;
        overflow: clip;
    `;
  return "";
};

export default overflow;
