const signupsActions = {
  SET_ADDITIONAL_INFO: "SET_ADDITIONAL_INFO",
  /** Sets the boolean value in the "Additional Info" tab for the "Ask for more information?" checkbox */
  setAdditionalInfo: (additionalInfo) => ({
    type: signupsActions.SET_ADDITIONAL_INFO,
    additionalInfo,
  }),
  SET_CURRENT_SIGNUP_LIST: "Set Current Signup List",
  /** Sets the signup currently being viewed by the user. */
  setCurrentSignupList: (signupList) => ({
    type: signupsActions.SET_CURRENT_SIGNUP_LIST,
    signupList,
  }),
  SET_IS_PER_SIGNUP_COST_TYPE: "setSignupListIsPerSignupCostType",
  /**
   * @param {boolean} payload
   */
  setSignupListIsPerSignupCostType: (payload) => ({
    type: signupsActions.SET_IS_PER_SIGNUP_COST_TYPE,
    payload,
  }),
  SET_SHOW_POLL_FIELDS: "setShowPollFields",
  /** Sets the state of showPollFields */
  setShowPollFields: (payload) => ({
    type: signupsActions.SET_SHOW_POLL_FIELDS,
    payload,
  }),
  SET_USER_SIGNUP_ITEMS: "userSignupItems",
  /** Sets the state of userSignupItems */
  setUserSignupItems: (payload) => ({
    type: signupsActions.SET_USER_SIGNUP_ITEMS,
    payload,
  }),
  SET_FETCH_PRICE_FLAG: "SET_FETCH_PRICE_FLAG",
  setFetchPriceFlag: (payload) => ({
    type: signupsActions.SET_FETCH_PRICE_FLAG,
    payload,
  }),
  SET_PAYMENT_AMOUNT_NEEDED: "setPaymentAmountNeeded",
  setPaymentAmountNeeded: (payload) => ({
    type: signupsActions.SET_PAYMENT_AMOUNT_NEEDED,
    payload,
  }),
};

export default signupsActions;
