import React, { createContext, useReducer } from "react";

const initialState = {
  shouldHover: false,
  pointerPosition: null,
  setPointerPosition: null,
  parentRef: null,
  scrollableRef: null,
  hoveringChildRef: null,
  forceHoverChildRef: null,
  hoveringChildDisabled: false,
  selectedChildRef: null,
};

const HoverReducer = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case "Should Hover":
      newState.shouldHover = true;
      return newState;

    case "Should Not Hover":
      newState.shouldHover = false;
      newState.hoveringChildRef = null;
      return newState;

    case "Set Hovering Child":
      newState.hoveringChildRef = action.childRef;
      newState.hoveringChildDisabled = action.disabled;
      if (action.pointerPosition)
        newState.pointerPosition = action.pointerPosition;
      return newState;

    case "Set Pointer Position Ref":
      newState.pointerPosition = action.pointerPosition;
      newState.setPointerPosition = action.setPointerPosition;
      return newState;

    case "Set Selected Child":
      newState.selectedChildRef = action.childRef;
      return newState;

    case "Remove Selected Child":
      if (newState.selectedChildRef === action.childRef)
        newState.selectedChildRef = null;
      return newState;

    case "Set Force Hover Child":
      newState.forceHoverChildRef = action.childRef;
      return newState;

    case "Remove Force Hover Child":
      if (newState.forceHoverChildRef === action.childRef)
        newState.forceHoverChildRef = null;
      return newState;

    default:
      return newState;
  }
};

export const HoverContext = createContext();

export function HoverStateProvider({ children }) {
  return (
    <HoverContext.Provider value={useReducer(HoverReducer, initialState)}>
      {children}
    </HoverContext.Provider>
  );
}
