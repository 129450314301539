import React, { useRef, createContext, forwardRef, useReducer } from "react";

import { FnrPopoverReducer } from "./Reducers";

export const FnrPopoverContext = createContext();

export const FnrPopoverWrapper = forwardRef(({ children }, forwardedRef) => {
  const buttonRef = useRef();
  const popoverRef = useRef();

  const context = {
    show: false,
    buttonRef,
    popoverRef,
  };

  const reducerContext = useReducer(FnrPopoverReducer, context);

  return (
    <FnrPopoverContext.Provider value={reducerContext}>
      {children}
    </FnrPopoverContext.Provider>
  );
});

FnrPopoverWrapper.displayName = "FnrPopoverWrapper";
