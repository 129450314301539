import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import InstantAuth from "Login/InstantAuth";

import FnrOverlayBackdrop from "UI/FnrOverlay/FnrOverlayBackdrop.js";
import { CSSColorVariables } from "UI/colors";

import BoardTheBus from "./BoardTheBus";
import GlobalComponents from "./GlobalComponents/GlobalComponents";
import HandleDevEnvPageStructure from "./HandleDevEnvPageStructure";
import Providers from "./Providers";
import Routes from "./Routes";

export default function FlocknoteApp() {
  return (
    <Router>
      <Providers>
        <CSSColorVariables />
        <HandleDevEnvPageStructure />
        <BoardTheBus />
        <Routes />
        <FnrOverlayBackdrop />
        <InstantAuth />
        <GlobalComponents />
      </Providers>
    </Router>
  );
}
