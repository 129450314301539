import { useEffect, useState } from "react";

import useComponentIsMounted from "Utilities/Hooks/useComponentIsMounted";
import useMutationObserver2 from "Utilities/useMutationObserver/useMutationObserver2";

const findElemInNodeList = (nodeList, selector) => {
  return new Promise((res) => {
    setTimeout(() => {
      for (let i = 0; i < nodeList.length; i++) {
        if (typeof nodeList[i]?.querySelector !== "function") continue;
        let queryResult = nodeList[i].querySelector(selector);
        if (queryResult) res(queryResult);
      }
      res(null);
    });
  });
};

export function usePortalElement(selector) {
  const [portalToElement, setPortalToElement] = useState(
    document.querySelector(selector)
  );

  const isMounted = useComponentIsMounted();

  useEffect(() => {
    if (isMounted && !portalToElement) {
      setPortalToElement(document.querySelector(selector));
    }
  }, [isMounted, portalToElement, selector, setPortalToElement]);

  const findPortalToElement = async ({ addedNodes, target }) => {
    const foundElement = await findElemInNodeList(
      [...addedNodes, target?.parentNode],
      selector
    );
    if (foundElement) setPortalToElement(foundElement);
  };

  useMutationObserver2({
    targetSelector: "body",
    handleMutation: findPortalToElement,
    options: { childList: true, subtree: true },
  });

  return portalToElement;
}
