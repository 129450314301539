import { useCallback } from "react";

import { useFlocknoteAppState } from "Flocknote/state";
import { useModal } from "Flocknote/stateHooks";

import { fnReportError } from "Utilities/fnReportError";
import { removeUrlParams } from "Utilities/urlParamTools";

import { useDrawer } from "./Drawer/drawerHooks";
import { contentTypes } from "./constants";

const useRemoveContentUrlParams = (contentType) => {
  const removeContentUrlParams = useCallback(() => {
    // Replace the history state so that the URL doesn't have the ugly modal params in it.
    removeUrlParams(`${contentType}Key`, `${contentType}Props`);
  }, [contentType]);

  return removeContentUrlParams;
};

const useContentUrlParam = (contentType, deleteAfterInit) => {
  const [{ modalData }] = useFlocknoteAppState();
  const [, { openModal }] = useModal();
  const [, { openDrawer }] = useDrawer();
  const removeContentUrlParams = useRemoveContentUrlParams(contentType);

  const init = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const contentKey = urlParams.get(`${contentType}Key`);
    const contentProps = urlParams.get(`${contentType}Props`);

    if (contentKey) {
      let contentPropsParsed;
      try {
        contentPropsParsed = JSON.parse(contentProps) || {};
      } catch (e) {
        fnReportError({
          message: `Unable to parse ${contentType}Props argument for ${contentKey}: ${contentProps}`,
        });
      }
      switch (contentType) {
        case contentTypes.drawer:
          openDrawer(contentKey, contentPropsParsed);
          break;
        case contentTypes.modal:
        default:
          openModal(contentKey, contentPropsParsed);
      }

      if (deleteAfterInit) {
        removeContentUrlParams();
      }
    }
  }, [
    contentType,
    openDrawer,
    openModal,
    deleteAfterInit,
    removeContentUrlParams,
  ]);

  return [modalData || {}, init];
};

const useModalUrlParams = () => {
  // https://testnet.flocknote.docker/give?contentKey=ALERT_EDITOR&contentProps={%22glossary%22:{%22title%22:%22example%22}}
  return useContentUrlParam(contentTypes.modal, true);
};

const useDrawerUrlParams = () => {
  // https://testnet.flocknote.docker/give?drawerKey=HOUSEHOLD_PROFILE&drawerProps={householdID:1}
  return useContentUrlParam(contentTypes.drawer, false);
};

export {
  useRemoveContentUrlParams,
  useContentUrlParam,
  useModalUrlParams,
  useDrawerUrlParams,
};
