import FnrFetch3, { callMockResponse } from "Utilities/FnrFetch/FnrFetch3.js";

import { mockData } from "./mockData";

export const SSO_KEYS = {
  initiateWePaySSO: "initiateWePaySSO",
  initiateStaxSSO: "initiateStaxSSO",
  initiateStaxSSOEnrollment: "initiateStaxSSOEnrollment",
};

export const SSO_ENDPOINTS = {
  [SSO_KEYS.initiateWePaySSO]: "/oauth/initiateWePaySSO",
  [SSO_KEYS.initiateStaxSSO]: "/payments/sso/initiateStax?forEnrollment=false",
  [SSO_KEYS.initiateStaxSSOEnrollment]:
    "/payments/sso/initiateStax?forEnrollment=true",
};

const API = {
  getFundItStatus: (postData) =>
    mockData.shouldMock
      ? callMockResponse(mockData.getFundItStatus)
      : new FnrFetch3(`/fundit/getFundItStatus`, postData),
  signup: (postData) => new FnrFetch3(`/give/signup`, postData),
  dismissPaymentsSplash: (postData) =>
    new FnrFetch3(`/dashboard/dismissPaymentsSplash`, postData),
  initiateWePaySSO: (postData = {}) =>
    new FnrFetch3(SSO_ENDPOINTS.initiateWePaySSO, postData),
  initiateStaxSSO: (postData = {}) =>
    new FnrFetch3(SSO_ENDPOINTS.initiateStaxSSO, postData),
  initiateStaxSSOEnrollment: (postData = {}) =>
    new FnrFetch3(SSO_ENDPOINTS.initiateStaxSSOEnrollment, postData),
  createFundItTestNote: () => new FnrFetch3(`/composer/createFundItTestNote`),
};

export default API;
