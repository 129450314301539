const removeUrlParams = (...params) => {
  const urlParams = new URLSearchParams(window.location.search);
  params.forEach((param) => {
    urlParams.delete(param);
  });
  const newParams = urlParams.toString();
  window.history.replaceState(
    {},
    document.title,
    `${window.location.pathname}${newParams ? `?${newParams}` : ""}`
  );
};

const addUrlParam = (param, value) => {
  const urlParams = new URLSearchParams(window.location.search);

  // if the param is already part of the url with the given value, do nothing.
  if (urlParams.get(param) === value) return;

  // remove the param first in case the param already exists and we a changing the value.
  urlParams.delete(param);
  urlParams.append(param, value);

  const newParams = urlParams.toString();
  window.history.replaceState(
    {},
    document.title,
    `${window.location.pathname}${newParams ? `?${newParams}` : ""}`
  );
};

export { removeUrlParams, addUrlParam };
