import styled from "styled-components/macro";

export default function UnoLogo({ color = "#278dc1", size, style }) {
  if (size || style)
    return (
      <UnoLogoWrapper style={{ width: size, height: size, ...style }}>
        <Svg color={color} />
      </UnoLogoWrapper>
    );

  return <Svg color={color} />;
}

export const UnoLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Svg = ({ color }) => (
  <svg
    id="uno_logo"
    data-name="Uno"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 249.36 207"
  >
    <title>uno</title>
    <path
      style={{ fill: color }}
      d="M249.36,109.07a29.86,29.86,0,0,0-21.41-28.63c.06-.77.12-1.54.12-2.32a29.86,29.86,0,0,0-29.86-29.86c-.65,0-1.28.06-1.92.1A29.85,29.85,0,0,0,150.9,32.8a35.76,35.76,0,0,0-50.7-2.92,29.81,29.81,0,0,0-45,12.38A40.38,40.38,0,0,0,26.09,72,40.32,40.32,0,0,0,3.4,125.89a40.33,40.33,0,0,0,23.6,51,40.57,40.57,0,0,0,5,5.9L24.5,198.22A10.81,10.81,0,1,0,44,207.64l7-14.43a40.21,40.21,0,0,0,8.66,1.06L52.3,213a10.81,10.81,0,1,0,20.14,7.88L81,198.89a33.74,33.74,0,0,0,3.88.24,34.12,34.12,0,0,0,17.77-5,33.94,33.94,0,0,0,25.94,1.33,29.72,29.72,0,0,0,37.59,1.79,41.52,41.52,0,0,0,7.39,1.54l8,20.33a10.81,10.81,0,1,0,20.14-7.88l-6.15-15.72a41.65,41.65,0,0,0,7.21-4.09L208.32,203a10.81,10.81,0,1,0,19.46-9.42L217.56,172.4c.2-.5.41-1,.58-1.51A29.8,29.8,0,0,0,237,133.2,29.78,29.78,0,0,0,249.36,109.07Zm-29-12.8a28.11,28.11,0,0,1-21,16.5c-4.91,1-5.85,3-4.72,7.64,2.75,11.41,4.47,22.95,2.51,34.77-1.64,9.91-6.49,16.41-13.66,18.35h0l0,0h0c-7.11,2.13-14.66-.82-21.29-8.37-7.91-9-12.54-19.71-16.23-30.85-1.51-4.57-3.37-5.76-8-4a28.11,28.11,0,0,1-26.56-2.92c-3.24-2.12-3.51-4.48-.57-7.06,5.66-5,11.55-9.54,19.36-10.61,4.42-.61,7.16-3,9.1-7.1a31.52,31.52,0,0,1,19.18-16.88h0l0,0h0A31.52,31.52,0,0,1,183.6,90c3.8,2.44,7.4,3.05,11.48,1.23,7.19-3.21,14.61-2.43,22-1.21C221,90.61,222,92.75,220.36,96.27Z"
      transform="translate(0 -20.71)"
    />
  </svg>
);
