import React from "react";

import styled from "styled-components/macro";

import { AnimatePresence, motion } from "framer-motion";

import errorMessages from "./errorMessageList.json";

const variants = {
  visible: { opacity: 1, height: "auto", scale: 1 },
  hidden: { opacity: 0, height: 0, scale: 0.5 },
};

export default function ErrorMessage({ errorCode }) {
  return (
    <AnimatePresence>
      {errorMessages[errorCode] ? (
        <Container
          key="errorContainer"
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{
            type: "tween",
            duration: 0.24,
          }}
        >
          <Inside>
            <Wrapper>
              <Icon className={errorMessages[errorCode].icon} />
              <div data-cy="errorMessage">
                {errorMessages[errorCode].text}

                {errorMessages[errorCode].subtext && (
                  <Subtext>{errorMessages[errorCode].subtext}</Subtext>
                )}
              </div>
            </Wrapper>
          </Inside>
        </Container>
      ) : null}
    </AnimatePresence>
  );
}

const Container = styled(motion.div)`
  margin-left: -24px;
  margin-right: -24px;
  overflow: hidden;
`;
const Inside = styled.div`
  padding: 10px 8px;
`;
const Wrapper = styled.div`
  background: linear-gradient(to bottom right, #ed699b, #ea4c89);
  border-radius: 22px;
  padding: 12px 18px;
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 6px;
  display: flex;
  align-items: center;
`;
const Icon = styled.div`
  font-size: 32px;
  margin-right: 18px;
`;
const Subtext = styled.div`
  font-size: 13px;
  padding-top: 6px;
  margin-top: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
`;
