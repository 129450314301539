import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";

import FlocknoteApp from "Flocknote";

import { getSentryEnvironment } from "./Utilities/Sentry/getSentryEnvironment";
import "./index.scss";

Sentry.init({
  dsn: "https://f58ae101bf0f6cad2520590daeaa153b@o4508342913466368.ingest.us.sentry.io/4508342915563520",
  integrations: [],
  environment: getSentryEnvironment(),
});

ReactDOM.render(<FlocknoteApp />, document.getElementById("fnReactHolder"));
