import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const Sun = ({ size }) => (
  <FnrSvg size={size} $virtue="hope" viewBox="0 0 36.08 22.21">
    <FnrPath
      $virtue="hope"
      d="M10.06,7.99c.33.54.96.7,1.47.4.52-.3.68-.94.37-1.5-.35-.61-.71-1.22-1.06-1.83-.21-.37-.54-.53-.96-.54-.78,0-1.3.84-.93,1.53.35.66.73,1.3,1.11,1.94Z"
    />
    <FnrPath
      $virtue="hope"
      d="M3.88,19.87c.68-.01,1.15-.45,1.16-1.06,0-.63-.48-1.09-1.16-1.1-.67,0-1.33,0-2,0-.69,0-1.17.46-1.17,1.08,0,.61.47,1.06,1.15,1.08.34,0,.68,0,1.01,0,.34,0,.68,0,1.01,0Z"
    />
    <FnrPath
      $virtue="hope"
      d="M30.26,13.72c.18-.06.37-.09.53-.17.62-.35,1.25-.7,1.86-1.07.53-.32.69-.95.41-1.45-.3-.53-.93-.72-1.49-.41-.64.35-1.28.73-1.9,1.11-.42.25-.59.75-.45,1.22.13.44.56.75,1.05.78Z"
    />
    <FnrPath
      $virtue="hope"
      d="M16.97,5.59c.01.61.46,1.05,1.05,1.06.6,0,1.08-.44,1.09-1.06.01-.72.01-1.45,0-2.17,0-.62-.47-1.08-1.08-1.08-.61,0-1.06.45-1.07,1.07,0,.37,0,.73,0,1.1,0,.36,0,.71,0,1.07Z"
    />
    <FnrPath
      $virtue="hope"
      d="M6.42,11.71c-.63-.38-1.26-.76-1.91-1.1-.21-.11-.48-.16-.71-.13-.47.06-.76.36-.87.82-.1.42.06.87.46,1.11.66.41,1.34.78,2.01,1.16.13.07.3.09.37.11.57,0,.99-.32,1.12-.76.13-.47-.05-.96-.47-1.22Z"
    />
    <FnrPath
      $virtue="hope"
      d="M24.79,8.5c.44.13.93-.04,1.19-.46.37-.61.73-1.23,1.08-1.85.32-.58.16-1.23-.36-1.53-.52-.3-1.17-.11-1.51.46-.35.59-.69,1.18-1.02,1.79-.09.17-.14.36-.21.55.04.51.33.9.82,1.05Z"
    />
    <FnrPath
      $virtue="hope"
      d="M34.24,17.71c-.69,0-1.37,0-2.06,0-.65,0-1.12.46-1.13,1.06,0,.63.45,1.08,1.12,1.1.35,0,.7,0,1.04,0,.35,0,.7,0,1.04,0,.66-.02,1.11-.46,1.11-1.07,0-.62-.47-1.08-1.13-1.09Z"
    />
    <FnrPath
      $virtue="hope"
      d="M28.86,19.87c0-.07,0-.14,0-.2.02-5.93-4.82-10.81-10.8-10.83-5.87-.01-10.83,4.66-10.84,10.81,0,.07,0,.15,0,.22h21.63Z"
    />
  </FnrSvg>
);

Sun.propTypes = { size: number };

export default Sun;
