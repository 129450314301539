import { useEffect } from "react";

import styled from "styled-components/macro";

import { motion, useAnimation } from "framer-motion";

export default function SentResent({ codeResendCount }) {
  const animation = useAnimation();

  useEffect(() => {
    if (codeResendCount > 1)
      animation.start({
        scale: [1, 1.16, 1],
        transition: { duration: 0.2 },
      });
  }, [codeResendCount, animation]);

  return (
    <SentResentContainer>
      <SentResentInside animate={animation}>
        <motion.div
          initial={{ opacity: 0, width: 0, scale: 0.7 }}
          animate={
            codeResendCount > 1 && { opacity: 1, scale: 1, width: "auto" }
          }
        >
          re
        </motion.div>
        <div>sent</div>
      </SentResentInside>
    </SentResentContainer>
  );
}

const SentResentContainer = styled(motion.span)`
  width: auto;
  display: inline-block;
  position: relative;
`;
const SentResentInside = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
