import { colorVariants } from "theme/constants";

const color = ({
  $variant,
  $dark,
  $colorVariant,
  $color,
  $virtue,
  $typography,
  $type,
  theme: {
    colors,
    palette: { text, icon, ...brandColors },
  },
}) => {
  // if FnrTypography passes in a type, use the official new colors.
  if ($typography && Boolean($type)) {
    return `color: ${
      text[$color] || brandColors[$color] || colors[$color] || $color
    };`;
  }

  // Attempt to retrive the governing virtue color
  if (icon[$virtue]) {
    return `color: ${icon[$virtue]};`;
  }

  const { divider, textPrimary, textLight, error, fnDarkGreen } = colors;
  switch ($colorVariant) {
    case colorVariants.dark:
      return `color: ${textLight};`;
    case colorVariants.error:
      return `color: ${error};`;
    case colorVariants.completed:
      return `color: ${fnDarkGreen};`;
    default: // continue
  }

  switch ($variant) {
    case "disabled":
      return `color: ${divider};`;
    case "info":
      if ($dark) return `color: ${textLight};`;
      return `color: ${brandColors.blue};`;

    default:
      return `color: ${
        colors[$color] || brandColors[$color] || $color || textPrimary
      };`;
  }
};

export default color;
