import { bool, func, string } from "prop-types";

import styled from "styled-components/macro";
import { fixToCorner, shadow } from "theme";
import { positionVariants } from "theme/constants";

import FnrIconButton from "UI/FnrIconButton/FnrIconButton";

const CloseButtonContainer = styled("div")`
  position: absolute;
  z-index: 2;
  ${shadow}
  ${fixToCorner}
`;

const FnrCloseButton = ({
  onClick,
  "aria-label": ariaLabel = "close",
  legacy,
  inverted = false,
  position = positionVariants.upperLeft,
  ...rest
}) => (
  <CloseButtonContainer
    data-cy="Modal_CloseButton_Container"
    $variant="button"
    $noShadow={!legacy}
    $noFixToCorner={legacy}
    $margin={6}
    $positionVariant={position}
    {...rest}
  >
    <FnrIconButton
      icon="fa-times fas"
      data-cy="Modal_CloseButton"
      aria-label={ariaLabel}
      onClick={onClick}
      variant={legacy ? "default" : inverted ? "inverted" : "secondary"}
    />
  </CloseButtonContainer>
);

FnrCloseButton.propTypes = {
  onClick: func.isRequired,
  "aria-label": string,
  legacy: bool,
  inverted: bool,
  position: string,
};

export default FnrCloseButton;
