import { useLocation } from "react-router-dom";

/**
 *  @description
 * This is a hook to get the query parameters from the URL. These are the parameters that come after the "?" in the URL. It has nothing to do with react-query.
 * @returns {URLSearchParams}
 */
export const useQueryParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params;
};
