import propTypes from "prop-types";
import React, { forwardRef } from "react";

import styled from "styled-components/macro";

import { AnimatePresence, motion } from "framer-motion";

import FnrCloseButton from "UI/FnrCloseButton";
import { scrollbarInvisibleThin } from "UI/scrollbarStyles";

const FnrDrawer = forwardRef(
  ({ show = true, onClose, width = 400, children, ...rest }, ref) => {
    return (
      <AnimatePresence>
        {show && (
          <OverlayContainer
            key="overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            {...rest}
          >
            <Drawer
              data-cy="FnrDrawer_Container"
              ref={ref}
              key="drawer"
              initial={{ right: -(width + 50) }}
              animate={{
                right: 0,
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  mass: 1,
                },
              }}
              exit={{ right: -(width + 50) }}
              $width={width}
            >
              <FnrCloseButton
                icon="fas fa-circle-times"
                onClick={onClose}
                aria-label="close"
                inverted
                data-cy="FnrDrawer_CloseButton"
              />
              <DrawerContents>{children}</DrawerContents>
            </Drawer>
          </OverlayContainer>
        )}
      </AnimatePresence>
    );
  }
);

export default FnrDrawer;

FnrDrawer.propTypes = {
  show: propTypes.bool,
  onClose: propTypes.func,
  width: propTypes.number,
  children: propTypes.node,
};

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 340px;
`;

const Drawer = styled(motion.div)`
  position: fixed;
  top: 2rem;
  margin-right: 1.5rem;
  border-radius: 1.25rem;
  width: ${({ $width }) => $width}px;
  max-width: calc(100% - 20px);
  z-index: 999;
  color: #ffffff;
  max-height: calc(100vh - 48px);
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 4px;
  display: flex;
  flex-direction: column;
  background: #13426b;
  background-image: linear-gradient(
    to right,
    #13426b,
    #124976,
    #0f5182,
    #0a588d,
    #016099
  );
  transition: width 300ms;
`;

const DrawerContents = styled("div")`
  overflow-y: auto;
  ${scrollbarInvisibleThin}
  scrollbar-width: none;
`;
