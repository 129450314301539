import { node, object } from "prop-types";
import React from "react";

import { StyleSheetManager, ThemeProvider } from "styled-components";
import theme from "theme";

import isPropValid from "@emotion/is-prop-valid";
import { AccountMergeProvider } from "AccountMerge/AccountMergeContext";
import { GlobalPaymentsProvider } from "Payments/GlobalPaymentsContext";
import { SignupsAppStateProvider } from "Signups/state";

import { ReactQueryProvider } from "Utilities/ReactQuery/ReactQueryProvider";

import FlocknoteAppStateProvider from "./state";

Providers.propTypes = {
  children: node,
  initialState: object,
};

export default function Providers({ children, initialState }) {
  const networkID =
    window.data && window.data.networkID ? window.data.networkID : null;

  return (
    <ReactQueryProvider>
      <FlocknoteAppStateProvider
        networkID={networkID}
        initialState={initialState}
      >
        <SignupsAppStateProvider>
          <AccountMergeProvider>
            <GlobalPaymentsProvider>
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
              </StyleSheetManager>
            </GlobalPaymentsProvider>
          </AccountMergeProvider>
        </SignupsAppStateProvider>
      </FlocknoteAppStateProvider>
    </ReactQueryProvider>
  );
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
