import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const Square = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 35.28">
    <FnrPath
      $virtue="courage"
      d="M28.55,7.13v21.02H7.53V7.13h21.02M31.02,2.93H5.06c-.95,0-1.73.77-1.73,1.73v25.95c0,.95.77,1.73,1.73,1.73h25.95c.95,0,1.73-.77,1.73-1.73V4.66c0-.95-.77-1.73-1.73-1.73h0Z"
    />
  </FnrSvg>
);

Square.propTypes = { size: number };

export default Square;
