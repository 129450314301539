/**
 * For those moments when you just want to tweak the thing by a few pixles. Do not abuse!
 * @param {object} param0
 * @param {number} param0.$xAdjust - adjusts element on the x axis
 * @param {number} param0.$yAdjust - adjusts element on the y axis
 */
const xyAdjust = ({ $xAdjust, $yAdjust }) => {
  if (!$xAdjust && !$yAdjust) return "";
  if ($xAdjust && !$yAdjust) return `transform: translateX(${$xAdjust}px);`;
  if (!$xAdjust && $yAdjust) return `transform: translateY(${$yAdjust}px);`;
  return `transform: translateX(${$xAdjust}px) translateY(${$yAdjust}px);`;
};

export default xyAdjust;
