import axios from "axios";
import { createUrl } from "./createUrl";

const FnrFetch = {
  post: (url, post = {}, useFormData = true) => {
    return new Promise((fnrFetchResolve, fnrFetchReject) => {
      if (useFormData) {
        axios({
          method: "post",
          url: createUrl(url),
          data: post,
          withCredentials: true,
        }).then((response) => {
          if (response.data.success) fnrFetchResolve(response.data);
          fnrFetchReject(response.error, response.data);
        });
      } else {
        axios
          .post(createUrl(url), post, { withCredentials: true })
          .then((response) => {
            if (response.data.success) fnrFetchResolve(response.data);
            fnrFetchReject(response.data.error, response.data);
          });
      }
    });
  },

  mock: (returnData, delay = 0.5) => {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          returnData.success
            ? resolve(returnData)
            : reject(returnData.error, returnData),
        delay * 1000
      );
    });
  },
};

export default FnrFetch;
