import { bool } from "prop-types";
import { forwardRef } from "react";

import styled from "styled-components/macro";
import { cursor } from "theme";

const SButton = styled("button")`
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  ${cursor};
  font-size: inherit;
  text-align: left;
  ${({ $fullWidth }) => ($fullWidth ? "width: 100%;" : "")}
`;

const FnrButtonWrapper = forwardRef(
  /**
   *
   * @param {object} param0
   * @param {boolean} param0.fullWidth - sets width of the button to 100%
   * @param {import("react").Ref} ref
   * @returns
   */
  ({ fullWidth, ...props }, ref) => (
    <SButton $fullWidth={fullWidth} ref={ref} role="button" {...props} />
  )
);

FnrButtonWrapper.propTypes = { fullWidth: bool };

export default FnrButtonWrapper;
