import { createContext, useCallback, useContext, useReducer } from "react";

const GlobalPaymentsContext = createContext();

const defaultState = {
  paymentMethodProviders: [], // {merchantToken:? string, paymentMethodCategory: credit_card || bank_account, paymentProvider: number, paymentProviderName: string}
  fetching: false,
  fetchComplete: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setState": {
      return {
        ...state,
        ...action.payload,
      };
    }

    case "setPaymentMethodProviders": {
      return {
        ...state,
        paymentMethodProviders: action.payload,
      };
    }
    default:
      return state;
  }
};

/**
 * A SINGLE instance of GlobalPaymentsProvider needs to be wrapped around any context / feature
 * that uses payment method management. Because payment management happens in so many places,
 * this has been added the the global app Providers component.
 *
 * The main initial use is to track the payment method providers.
 * There are hooks in useGlobalPaymentsStateHooks to get and update that information
 * when it is retrieved from the backend.
 */

function GlobalPaymentsProvider({ initialState = {}, children }) {
  const defaultAndInitialState = {
    ...defaultState,
    ...initialState,
  };
  const [state, dispatch] = useReducer(reducer, defaultAndInitialState);

  const setState = useCallback(
    (payload) => {
      dispatch({ type: "setState", payload });
    },
    [dispatch]
  );

  return (
    <GlobalPaymentsContext.Provider
      value={{
        state,
        dispatch,
        setState,
      }}
    >
      {children}
    </GlobalPaymentsContext.Provider>
  );
}

function useGlobalPayments() {
  const context = useContext(GlobalPaymentsContext);
  if (context === undefined) {
    throw new Error(
      `useGlobalPayments must be used within the GlobalPaymentsProvider`
    );
  }
  return context;
}

export { GlobalPaymentsProvider, useGlobalPayments };
