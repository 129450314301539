import { useHistory } from "react-router-dom";

import styled from "styled-components/macro";

//import { useFundItPage } from "Payments/FundItPage/FundItPageContext";
// import { getMissingDataForSignup } from "Payments/FundItPage/useFundItSignupProgress";
import FnrButton, { ButtonIcon, ButtonText } from "UI/FnrButton3";
import FnrModal from "UI/FnrModal";
import { colors } from "UI/colors";

import { useLocalStorage } from "Utilities/Hooks/useLocalStorage";

// import { ModalWrapper } from "./ModalStyles";

const hasMissingNetworkInfo = (missingData, networkRequiredDataFields) => {
  //   const networkRequiredDataFields = [
  //     "hasAddress",
  //     "isAddressValid",
  //     "hasDenomination",
  //     "hasWebsite",
  //   ];
  return networkRequiredDataFields.some((requiredField) =>
    missingData.includes(requiredField)
  );
};

const hasMissingUserInfo = (missingData, userRequiredDataFields) => {
  //   const userRequiredDataFields = [
  //     "userEmailVerified",
  //     "userHasFirstName",
  //     "userHasLastName",
  //     "userPhoneVerified",
  //   ];
  return userRequiredDataFields.some((requiredField) =>
    missingData.includes(requiredField)
  );
};

const getMissingDataForSignup = (data) => {
  const properties = [
    "addressInUS",
    "hasAddress",
    "isAddressValid",
    "hasDenomination",
    "hasWebsite",
    "hasSuperAdminContact",
    "userEmailVerified",
    "userHasFirstName",
    "userHasLastName",
    "userPhoneVerified",
  ];
  return properties.filter((prop) => !data[prop]);
};

export default function MissingNetworkInfoModal({
  show,
  onClose = () => {},
  onContinue = () => {},
  data,
  userID,
  subTitle,
  networkRequiredDataFields = [],
  userRequiredDataFields = [],
  continueButtonText,
}) {
  let missingData = getMissingDataForSignup(data.missingData) || [];

  //   const bypassMissingInfo = () => {
  //     onContinue();
  //   };

  return (
    <FnrModal show={show}>
      <Wrapper>
        <Top>
          <Title>More Information is Needed</Title>
          <SubTitle>{subTitle}</SubTitle>
        </Top>
        <Bottom>
          <MissingNetworkDataSection
            missingData={missingData}
            requiredFields={networkRequiredDataFields}
            onClose={onClose}
          />
          <MissingUserDataSection
            missingData={missingData}
            requiredFields={userRequiredDataFields}
            userID={userID}
            onClose={onClose}
          />
          <ButtonsWrapper>
            <FnrButton
              color="gray"
              size="medium"
              style={{ width: "fit-content" }}
              onClick={onClose}
            >
              <ButtonText>Cancel</ButtonText>
            </FnrButton>
            <FnrButton
              color="green"
              size="medium"
              style={{ width: "fit-content" }}
              onClick={onContinue}
            >
              <ButtonText>{continueButtonText}</ButtonText>
            </FnrButton>
          </ButtonsWrapper>
        </Bottom>
      </Wrapper>
    </FnrModal>
  );
}

const Wrapper = styled.div`
  width: 60vw;
  max-width: 600px;
  background: ${colors.fnLtGray};
  border-radius: 14px;
  font-size: 16px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 550px) {
    max-width: 85vw;
    width: inherit;
  }
`;

const Top = styled.div`
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  padding: 20px;
  padding-bottom: 0;
  font-size: 22px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Title = styled.div``;

const SubTitle = styled.div`
  font-size: 16px;
  opacity: 0.8;
  padding: 0 20px;
`;

const Bottom = styled.div`
  padding: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

function MissingNetworkDataSection({ missingData, onClose, requiredFields }) {
  const hasAddress = missingData.includes("hasAddress");
  const isAddressValid = missingData.includes("isAddressValid");
  const hasWebsite = missingData.includes("hasWebsite");
  const hasDenomination = missingData.includes("hasDenomination");

  const history = useHistory();

  const openNetworkSettings = () => {
    onClose();
    history.replace({
      pathname: `${history.location.pathname}/settings/network`,
    });
  };

  if (!hasMissingNetworkInfo(missingData, requiredFields)) return null;

  return (
    <Section>
      <SectionContent>
        <SectionTitle>
          In your Network Settings, please enter the following missing
          information for your organization:
        </SectionTitle>
        {((hasAddress && requiredFields.includes("hasAddress")) ||
          (isAddressValid && requiredFields.includes("isAddressValid"))) && (
          <LineItem>
            <Icon className="fad fa-church" />
            Physical Address
          </LineItem>
        )}
        {hasWebsite && requiredFields.includes("hasWebsite") && (
          <LineItem>
            <Icon className="fad fa-browser" />
            Website
          </LineItem>
        )}
        {hasDenomination && requiredFields.includes("hasDenomination") && (
          <LineItem>
            <Icon className="fad fa-praying-hands" />
            Denomination
          </LineItem>
        )}
        <FnrButton
          color="blue"
          size="medium"
          style={{ width: "fit-content" }}
          onClick={openNetworkSettings}
        >
          <ButtonIcon fal fa-cog />
          <ButtonText>Open Network Settings</ButtonText>
        </FnrButton>
      </SectionContent>
    </Section>
  );
}

function MissingUserDataSection({
  missingData,
  requiredFields,
  userID,
  onClose,
}) {
  const hasSuperAdminContact = missingData.includes("hasSuperAdminContact");
  const userEmailVerified = missingData.includes("userEmailVerified");
  const userPhoneVerified = missingData.includes("userPhoneVerified");
  const userHasFirstName = missingData.includes("userHasFirstName");
  const userHasLastName = missingData.includes("userHasLastName");

  const [profileTabs, setProfileTab] = useLocalStorage("userprofile_tab");

  const handleProfileTab = () => {
    let clone = [...(profileTabs || [])];
    if (clone.includes("info")) {
      const index = clone.indexOf("info");
      clone.splice(index, 1);
    } else {
      clone.pop();
    }
    clone = [...["info"], ...clone];
    setProfileTab(clone);
  };

  const openUserProfile = () => {
    onClose();
    handleProfileTab();
    window.fnReactBus.loadUserProfile(userID);
  };

  if (!hasMissingUserInfo(missingData, requiredFields)) return null;

  return (
    <Section>
      <SectionContent>
        <SectionTitle>
          Please enter the missing information from your profile:
        </SectionTitle>
        {(userHasFirstName || userHasLastName) &&
          (requiredFields.includes("userHasFirstName") ||
            requiredFields.includes("userHasLastName")) && (
            <LineItem>
              <Icon className="fad fa-file-signature" />
              Your first or last name is missing
            </LineItem>
          )}
        {userEmailVerified && requiredFields.includes("userEmailVerified") && (
          <LineItem>
            <Icon className="fad fa-envelope" />
            Your email address is missing
          </LineItem>
        )}
        {userPhoneVerified && requiredFields.includes("userPhoneVerified") && (
          <LineItem>
            <Icon className="fad fa-phone" />
            Your phone number is missing
          </LineItem>
        )}
        {/* {hasSuperAdminContact && (
          <LineItem>
            <Icon className="fad fa-user-check" />
            No Super Admin with first name, last name, phone number, and email
          </LineItem>
        )} */}
        <FnrButton
          color="blue"
          size="medium"
          style={{ width: "fit-content" }}
          onClick={openUserProfile}
        >
          <ButtonIcon fal fa-user-alt />
          <ButtonText>Open My Profile</ButtonText>
        </FnrButton>
      </SectionContent>
    </Section>
  );
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-size: 16px;
`;

const SectionContent = styled.div`
  background: #fff;
  border-radius: 18px;
  border: 1px solid #d9dbe5;
  box-shadow: 2px 2px 8px rgb(0 0 0 / 5%);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 22px;
`;

const Icon = styled.div`
  font-size: 32px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
