import { useCallback } from "react";

import * as drawerKeys from "Flocknote/GlobalComponents/Drawer/drawerKeys";
import flocknoteActions from "Flocknote/actions";
import { useFlocknoteAppState } from "Flocknote/state";

import { fnReportError } from "Utilities/fnReportError";
import { addUrlParam } from "Utilities/urlParamTools";

import { contentTypes } from "../constants";
import { useRemoveContentUrlParams } from "../urlParamHooks";

/**
 * @typedef DrawerState
 * @property {boolean} isOpen - is the drawer open?
 * @property {string} drawerKey - the key to the current drawer contents (see src/Flocknote/GlobalComponents/Drawer/Drawer.js).
 * @property {object} drawerProps - the props to be passed to the component being render by the drawer.
 */

const drawerKeysByValue = Object.fromEntries(
  Object.values(drawerKeys).map((elem) => [elem, elem])
);

/**
 * @typedef OpenDrawer - opens the modal with the given contentkey and contentProps.
 * @type {function}
 * @param {string} drawerKey
 * @param {object} drawerProps
 */

/**
 * @typedef DrawerApi
 * @property {function} closeDrawer - closes the modal and clears the contentKey and contentProps
 * @property {OpenDrawer} openDrawer
 * @property {function} reorderZindex
 */

/**
 * returns state and interface to interact with the global modal component.
 *
 * @returns {[DrawerState, DrawerApi]}
 */
const useDrawer = () => {
  const [
    {
      drawer: { isOpen, drawerKey, drawerProps },
    },
    dispatch,
  ] = useFlocknoteAppState();
  const removeContentUrlParams = useRemoveContentUrlParams(contentTypes.drawer);

  const closeDrawer = useCallback(() => {
    dispatch(flocknoteActions.closeDrawer());
    removeContentUrlParams();
  }, [dispatch, removeContentUrlParams]);

  const openDrawer = useCallback(
    // pass in onClose as a special contentProp to fire whatever you need when the modal closes:
    // openModa('someKey',' {onClose: () => {}})
    (newDrawerKey, newDrawerProps) => {
      if (drawerKeysByValue[newDrawerKey]) {
        dispatch(flocknoteActions.openDrawer(newDrawerKey, newDrawerProps));
        addUrlParam("drawerKey", newDrawerKey);
      } else {
        fnReportError({
          message: `${newDrawerKey} was not found in Flocknote/GlobalComponents/Modal/contentKeys. Expected one of:\n\t${Object.values(
            drawerKeysByValue
          ).join("\n\t")}`,
        });
      }
    },
    [dispatch]
  );

  const reorderZindex = useCallback(
    (thingToDoWhileClosed) => {
      if (!isOpen) return;
      dispatch(flocknoteActions.closeDrawer());
      setTimeout(() => {
        thingToDoWhileClosed();
        dispatch(flocknoteActions.openDrawerWithOldProps());
      }, 300);
    },
    [dispatch, isOpen]
  );

  return [
    { isOpen, drawerKey, drawerProps },
    { closeDrawer, openDrawer, reorderZindex },
  ];
};

export { useDrawer };
