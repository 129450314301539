const marginBottom = ({ $variant, $active, $noActiveMargin }) => {
  switch ($variant) {
    case "accordion":
      if ($noActiveMargin) return `margin-bottom: 2px;`;
      return $active
        ? `margin-bottom: 22px; transition: margin-bottom .3s ease-in;`
        : "margin-bottom: 0px; transition: margin-bottom .3s ease-in;";
    case "hidden":
      return "margin-bottom: 0px; transition: margin-bottom .3s ease-in;";
    case "container":
    case "input":
    case "modal":
      return `margin-bottom: 0px;`;
    default:
      return `margin-bottom: 16px;`;
  }
};

// We need this due to FnrRadioButtonOption having margins applied inside the child component. 😢
const marginBottomCorrecter = () => "margin-bottom: 10px;";

export { marginBottomCorrecter };
export default marginBottom;
