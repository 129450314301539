import { number } from "prop-types";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const CircleFilled = ({ size }) => (
  <FnrSvg size={size} viewBox="0 0 36.08 35.28">
    <FnrPath
      $virtue="humility"
      d="M18.11,3.21c-7.96,0-14.41,6.45-14.41,14.41s6.45,14.41,14.41,14.41,14.41-6.45,14.41-14.41S26.07,3.21,18.11,3.21h0Z"
    />
  </FnrSvg>
);
CircleFilled.propTypes = { size: number };
export default CircleFilled;
