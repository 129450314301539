import { useEffect, useMemo, useState } from "react";

import isEqual from "lodash/isEqual";

/**
 * Like useMemo, but does not trigger a dep change unless the object has actually changed, not just a new reference.
 *
 * Use this carefully as misuse could make your problems worse. Make sure to wrap your getValue in a callback to ensure things work as expected.
 * @param {function} getValue - the function that returns the value to be memoized
 * @returns
 */
const useMemoDeep = (getValue) => {
  const value = useMemo(getValue, [getValue]);
  const [stateValue, setStateValue] = useState(value);

  useEffect(() => {
    if (!isEqual(value, stateValue)) {
      setStateValue(value);
    }
  }, [value, stateValue]);

  return stateValue;
};

export default useMemoDeep;
