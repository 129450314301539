import { Suspense, lazy, memo, useRef } from "react";

import styled from "styled-components";
import { padding, themeVariants } from "theme";

import FnrDrawer from "UI/FnrDrawer";
import FnrDynamicHeight from "UI/FnrDynamicHeight";
import RunningUno from "UI/RunningUno";

import { useOnClickOutside } from "Utilities/Hooks/useOnClickOutside";
import isAncestorOf from "Utilities/elementFunctions/isAncestorOf";

import { useWidth } from "./configHooksByDrawerKey";
import { useDrawer } from "./drawerHooks";
import { HOUSEHOLD_PROFILE } from "./drawerKeys";

const HouseholdProfile = lazy(() => import("HouseholdProfile"));

const DrawerContents = memo(() => {
  const [{ drawerKey, drawerProps }] = useDrawer();
  let Component;

  // Add additional case statements here for the components you'd like to add.
  switch (drawerKey) {
    case HOUSEHOLD_PROFILE:
      Component = HouseholdProfile;
      break;
    default:
      Component = () => null;
  }
  return <Component {...drawerProps} isDrawer />;
});

const ContentsWrapper = styled("div")`
  ${padding}
`;

const drawerCancelKey = "drawerCancelKey";

const Drawer = () => {
  const [{ isOpen, drawerProps, drawerKey }, { closeDrawer }] = useDrawer();
  const width = useWidth({ drawerKey });
  const wrapperRef = useRef();

  const handleClose = () => {
    // pass in onClose as a special contentProp to fire whatever you need when the modal closes:
    // openModa('someKey',' {onClose: () => {}})
    if (drawerProps?.onClose) drawerProps.onClose();
    closeDrawer();
  };

  useOnClickOutside(
    wrapperRef,
    ({ target } = {}) => {
      if (
        isAncestorOf(
          document.querySelector(".user_profile_wrapper_higher_z_index"),
          target,
          false
        )
      )
        return;
      handleClose();
    },
    drawerCancelKey
  );

  return (
    <FnrDrawer
      show={isOpen}
      onClose={handleClose}
      width={width}
      data-cy="Global_Drawer"
      ref={wrapperRef}
      className="react_global_drawer_contents_wrapper"
    >
      <FnrDynamicHeight containerRef={wrapperRef}>
        <ContentsWrapper $variant={themeVariants.premium}>
          <Suspense fallback={<RunningUno />}>
            <DrawerContents />
          </Suspense>
        </ContentsWrapper>
      </FnrDynamicHeight>
    </FnrDrawer>
  );
};

export { drawerCancelKey };
export default Drawer;
