import random from "lodash/random";
import { name } from "ntc";

export const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
export const hexRegexFind = /#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i;

export function colorIsLight(colorCode, offset = 0) {
  var luma = getLuma(colorCode);

  if (luma < 140 + offset) return false;
  else return true;
}

export function ensureLeadingHex(hexCode = "") {
  return hexCode[0] === "#" ? hexCode : `#${hexCode}`;
}

function getLuma(hexCode) {
  const colorCode = ensureLeadingHex(hexCode);

  const { r, g, b } = hexToRgb(colorCode) || {};

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

export function rgbToHex({ r, g, b }) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export function hexToRgb(hexCode) {
  const hex = ensureLeadingHex(hexCode);

  var result = hexRegex.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;

  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
}

export function lightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  // original from https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  // but had issue with leading zeros.
  // return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

  var string = "000000" + (g | (b << 8) | (r << 16)).toString(16);
  return (usePound ? "#" : "") + string.substring(string.length - 6);
}

export function getBackgroundGradientFromColorScheme(
  colorScheme,
  USE_GENERATED_COLOR_SCHEMES = true
) {
  let bgString = "linear-gradient(135deg, ";
  const defaultBlueGradient = `rgba(78, 162, 198, 0.8) 0%, rgba(149, 228, 209, 0.8) 100%`;

  if (!colorScheme?.length || !USE_GENERATED_COLOR_SCHEMES)
    return bgString + defaultBlueGradient + ")";

  colorScheme.forEach((val) => {
    if (!String(val).startsWith("#")) {
      bgString += `#`;
    }
    bgString += `${val}, `;
  });

  bgString = bgString.slice(0, bgString.length - 2); // remove the final ", " characters
  bgString += ")";

  return bgString;
}

export function colorName(hexCode) {
  const hex = ensureLeadingHex(hexCode);
  return name(hex)[1];
}

/** @typedef {'light'|'dark'|'any'} ColorType */

/**
 *
 * @param {ColorType} type
 * @returns {string}
 */
export function randomHexCode(type = "any") {
  const r = random(0, 255);
  const g = random(0, 255);
  const b = random(0, 255);
  const hexCode = rgbToHex({ r, g, b });

  if (type === "light" && !colorIsLight(hexCode)) {
    return randomHexCode(type);
  }
  if (type === "dark" && colorIsLight(hexCode)) {
    return randomHexCode(type);
  }
  return hexCode;
}

/**
 * @param {object} param0
 * @param {number} param0.amount - the number of random hex code you want!
 * @param {ColorType} param0.type - the type of color you want
 * @returns {Array<string>}
 */
export function randomHexArray({ amount = 6, type } = {}) {
  const hexArray = Array.from(Array(amount)).map(() => randomHexCode(type));

  // ensure there are no duplicates
  if (Array.from(new Set(hexArray)).length !== amount)
    return randomHexArray(amount, type);
  return hexArray;
}
