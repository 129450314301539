import PropTypes from "prop-types";
import { forwardRef, useRef } from "react";

import styled from "styled-components/macro";
import { center as themeCenter } from "theme";

import classNames from "classnames";

import CustomIcon, { customIconKeys } from "./CustomIcon";

const FnrIcon = forwardRef(
  /**
   * Uses the global font awesome classes to display an icon.
   * @param {object} param0
   * @param {object} param0.style
   * @param {string} param0.icon - the string representing the icon
   * @param {boolean} param0.center - Gives the container a full width and centers the icon.
   * @param {import("react").RefObject} forwardedRef
   */
  ({ style, icon, center, inline = false, ...classes }, forwardedRef) => {
    const customIcon = customIconKeys[icon];
    if (icon && !customIcon) {
      if (icon.substr(0, 3) === "fa-") classes[icon] = true;
      else classes[`fa-${icon}`] = true;
    }
    const iconClass = classNames({ ...classes });
    const localRef = useRef();
    const ref = forwardedRef || localRef;
    return (
      <FnrIconWrapper
        style={{ "--fa-secondary-opacity": 0.6, ...style }}
        ref={ref}
        $noCenter={!center}
        className={classNames({ inline })}
        data-cy="FnrIcon_Container"
      >
        {customIcon ? (
          <CustomIcon icon={customIcon} containerRef={ref} />
        ) : (
          <div role="presentation" className={iconClass} />
        )}
      </FnrIconWrapper>
    );
  }
);

FnrIcon.propTypes = {
  icon: PropTypes.string,
  center: PropTypes.bool,
  inline: PropTypes.bool,
  style: PropTypes.object,
};

FnrIcon.displayName = "FnrIcon";
export default FnrIcon;

export const FnrIconWrapper = styled.div`
  ${themeCenter}
  &.inline {
    display: inline-block;
  }
`;
