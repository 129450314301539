/*
Takes in an amount in cents. Returns amount in dollars.

If amount in dollars ends with ".00", returns only whole number. Otherwise includes 2 decimal places.

Examples:
10011 => 100.11
10010 => 100.10
10000 => 100
*/

export function getAmountInDollarsClean(amountInCents) {
  const amountInDollars = Number(amountInCents / 100).toFixed(2);

  return String(amountInDollars).endsWith(".00")
    ? Number(amountInDollars).toFixed(0)
    : amountInDollars;
}

export function getAmountInDollarsAndCents(amountInCents) {
  const dollarAndCents = Number(amountInCents / 100).toFixed(2);
  return dollarAndCents;
}
