import { useRef } from "react";

export default function usePointerPosition(initialPosition = {}) {
  const pointerPosition = useRef(initialPosition);

  const setPointerPosition = (arg1, arg2) => {
    let newPosition;

    //framer motion
    if (arg2?.point?.x && arg2.point.y) newPosition = arg2.point;
    //regular
    else if (arg1?.x && arg1.y) newPosition = arg1;

    pointerPosition.current = newPosition;
  };

  return [pointerPosition.current, setPointerPosition];
}
