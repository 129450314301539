const typographyTypes = {
  header: "header",
  subheader: "subheader",
  copy: "copy",
  accent: "accent",
  number: "number",
};

const fontFamily = ({ $type }) => {
  switch ($type) {
    case typographyTypes.subheader:
    case typographyTypes.header:
      return `font-family: "Merriweather", serif;`;
    case typographyTypes.copy:
    case typographyTypes.accent:
      return `font-family: "Raleway", sans-serif;`;
    case typographyTypes.number:
    default:
      return "";
  }
};

export { typographyTypes };
export default fontFamily;
