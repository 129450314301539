import { useEffect, useMemo } from "react";


/**
 * A custom hook that will observe a target element on the DOM for mutations.
 * @param {targetSelector} string - A css selector for the element to observe. Examples: "body", "#myId", ".myClass"
 * @param {handleMutation} function - A callback function that will be called when a mutation is observed.
 * @returns null
 * 
 * @example
 * This WAS used in useManageProfileField.js. But this could come in handy again.
 * It watched for when the custom field manager was removed from the DOM and closed the modal
 * 
 * useMutationObserver2({
    targetSelector: "body",
    handleMutation: closeModalWhenFieldManagerClosed,
    options: { childList: true },
  });
 */
export default function useMutationObserver2({
  targetSelector,
  handleMutation,
  options,
}) {
  const observer = useMemo(
    () =>
      new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          handleMutation(mutation);
        });
      }),
    [handleMutation]
  );

  useEffect(() => {
    let el = document.querySelector(targetSelector);

    if (observer && el) {
      observer.observe(el, options);
      return () => observer.disconnect();
    }
  }, [targetSelector, observer, options]);
}