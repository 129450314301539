import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import styled from "styled-components/macro";

import { useFlocknoteAppState } from "Flocknote/state";

import API from "Login/API";
import { useAnimation } from "framer-motion";

import FnrLoadingBricks from "UI/FnrLoadingBricks2";

import FnrEnvironment from "Utilities/FnrEnvironment";

import { CodeInputWrapper } from "../styles";

TextCodeInput.propTypes = {
  textCode: PropTypes.string.isRequired,
  setTextCode: PropTypes.func.isRequired,
  sendingTextCode: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  setErrorCode: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
};

export default function TextCodeInput({
  textCode,
  setTextCode,
  sendingTextCode,
  errorCode,
  setErrorCode,
  submitting,
  setSubmitting,
}) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const loginRef = useRef();
  const [
    {
      instantAuth: { callback, route, postData },
    },
    flocknoteAppDispatch,
  ] = useFlocknoteAppState();

  const controls = useAnimation();

  const changeTextCode = (e) => {
    if (e.target.value.length > 4) return;

    if (/[^0-9]/g.test(e.target.value)) return;

    setErrorCode(null);
    setTextCode(e.target.value);
  };

  const handleInstantAuth = useCallback(
    async (route, postData) => {
      if (FnrEnvironment.shouldUseDevAPI()) {
        try {
          const sent = await API.instantlyAuthenticate(route, postData);

          if (sent.success && sent.instantAuth.success) {
            return sent;
          } else {
            setTextCode("");
            setSubmitting(false);
            setErrorCode("instantAuthFailed");
          }
          return;
        } catch (e) {
          console.log("e", e);
          setTextCode("");
          setSubmitting(false);
          setErrorCode("instantAuthFailed");
          return;
        }
      }

      try {
        const sent = await API.instantlyAuthenticate(route, postData).post();

        if (sent.success && sent.instantAuth.success) {
          return sent;
        } else {
          setTextCode("");
          setSubmitting(false);
          setErrorCode("instantAuthFailed");
        }
        return;
      } catch (e) {
        console.log("e", e);
        setTextCode("");
        setSubmitting(false);
        setErrorCode("instantAuthFailed");
        return;
      }
    },
    [setErrorCode, setSubmitting, setTextCode]
  );

  const handleTextCodeComplete = useCallback(async () => {
    const newPostData = {
      ...postData,
      instantAuth: { authCode: textCode },
      // instantAuthCode: textCode, // This is only for testing purposes. Delete it at some point.
    };
    setSubmitting(true);
    setHasSubmitted(true);

    const res = await handleInstantAuth(route, newPostData);

    if (res?.success && res?.instantAuth?.success) {
      callback(res);
      flocknoteAppDispatch({ type: "Hide Instant Auth" });
      setTextCode("");
      setSubmitting(false);
    } else if (!res?.success || !res?.instantAuth?.success) {
      console.log("res", res);
      setTextCode("");
      loginRef.current.focus();
    }
  }, [
    textCode,
    setTextCode,
    setSubmitting,
    handleInstantAuth,
    route,
    postData,
    callback,
    flocknoteAppDispatch,
  ]);

  // Automatically logs in when full text code is entered
  useEffect(() => {
    // sets hasSubmitted to false so they can submit again if they enter a new code
    if (textCode.length < 4) {
      setHasSubmitted(false);
    }

    // Runs instant auth when full text code is entered
    if (textCode.length === 4 && !submitting && !hasSubmitted) {
      setTimeout(handleTextCodeComplete, 500);
    }
  }, [textCode, submitting, hasSubmitted, handleTextCodeComplete]);

  // Shakes the input if there is an error
  useEffect(() => {
    if (errorCode) {
      controls.start({
        x: [0, -6, 6, -6, 6, -6, 6, -6, 6, 0],
        transition: {
          duration: 0.65,
          delay: 0.35,
        },
      });
    }
  }, [errorCode, controls]);

  return (
    <Wrapper>
      <FnrLoadingBricks
        loading={submitting && !sendingTextCode}
        color={"#016099"}
      >
        {textCode.length === 0 && (
          <>
            <Placeholder>
              <Circle />
              <Circle />
              <Circle />
              <Circle />
            </Placeholder>
          </>
        )}
        <CodeInputWrapper>
          <Input
            data-cy="textCode"
            ref={loginRef}
            type="text"
            inputMode="numeric" // should open numeric keyboard on mobile
            pattern="[0-9]*" // should force iOS to use numeric keyboard
            onChange={changeTextCode}
            value={textCode}
            autoFocus={true}
            disabled={submitting}
            autoComplete="one-time-code"
          />
        </CodeInputWrapper>
      </FnrLoadingBricks>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 200px;
  margin: 20px auto;
  position: relative;
  border-radius: 15px;
  border: 1px solid #bec3c7;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px inset;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-size: 50px;
  line-height: 50px;
  padding: 8px 12px;
  font-weight: 600;
  color: #474747;
  border-radius: 5px;
  background: none;
  border: none;
  font-variant-numeric: tabular-nums;
  outline: none;
  letter-spacing: 12px;
  text-indent: 12px;
  position: relative;
  z-index: 100;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 14px;
`;
const Circle = styled.div`
  background: #98a4a5;
  width: 18px;
  height: 18px;
  border-radius: 20px;
`;
