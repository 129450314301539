import { css } from "styled-components";

import { colors } from "UI/colors";

export const scrollbarDarkThin = css`
  scrollbar-width: thin;
  scrollbar-color: #cbcfd4 ${colors.fnScrollbarDarkGray};

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.fnScrollbarDarkGray};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cbcfd4;
    border-radius: 6px;
    border: 3px solid ${colors.fnScrollbarDarkGray};
  }
`;

export const scrollbarLightThin = css`
  scrollbar-width: thin;
  scrollbar-color: #9fa2a5 #f8f8f8;

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: #f8f8f8;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9fa2a5;
    border-radius: 6px;
    border: 3px solid #f8f8f8;
  }
`;

export const scrollbarInvisibleThin = css`
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0%) rgba(0, 0, 0, 0%);

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0%);
  }
`;

export const scrollbarDarkThin2 = css`
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 10%) rgba(0, 0, 0, 10%);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 10%);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 20%);
    border-radius: 6px;
  }
`;
