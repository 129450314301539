import { number } from "prop-types";
import { useMemo } from "react";

import FnrPath from "../components/FnrPath";
import FnrSvg from "../components/FnrSvg";

const TriangleFilled = ({ size }) => {
  const top = useMemo(() => {
    if (size >= 24) return 3;
    if (size >= 18) return 2.5;
    if (size >= 16) return 1.75;
    if (size >= 14) return 1;
    if (size <= 11) return -1;
    return undefined;
  }, [size]);
  return (
    <FnrSvg size={size} top={top} viewBox="0 0 36.08 35.28">
      <FnrPath
        $virtue="veracity"
        d="M5.01,3.17c-.37-.37-.98-.45-1.37-.12-.31.26-.51.66-.51,1.1v25.9c0,1.38,1.12,2.5,2.5,2.5h25.9c1.06,0,1.58-1.29.83-2.03L5.01,3.17Z"
      />
    </FnrSvg>
  );
};

TriangleFilled.propTypes = { size: number };

export default TriangleFilled;
