import { positionVariants } from "theme/constants";

const fixToCorner = ({ $noFixToCorner, $positionVariant, $margin = 8 }) => {
  if ($noFixToCorner) return "";
  switch ($positionVariant) {
    case positionVariants.upperRight:
      return `
          position: absolute;
          right: ${$margin}px;
          top: ${$margin}px;
        `;
    case positionVariants.lowerLeft:
      return `
          position: absolute;
          left: ${$margin}px;
          bottom: ${$margin}px;
        `;
    case positionVariants.upperLeft:
      return `
          position: absolute;
          left: ${$margin}px;
          top: ${$margin}px;
        `;
    case positionVariants.lowerRight:
    default:
      return `
      position: absolute;
      right: ${$margin}px;
      bottom: ${$margin}px;
      `;
  }
};

export default fixToCorner;
