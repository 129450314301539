const opacity = ({ $variant, $opacity, $scale }) => {
  if ($opacity) return `opacity: ${$opacity};`;
  switch ($variant) {
    case "hidden":
      return `
          opacity: 0;
          ${$scale ? "transform: scale(.75);" : ""}
          transition: opacity 0.3s, transform 0.3s;
          * { pointer-events: none; }
        `;
    case "shown":
      return `
        opacity: 1;
        ${$scale ? "transform: scale(1);" : ""}
        transition: opacity 0.3s, transform 0.3s;`;
    case "disabled":
      return `opacity: 0.8;`;
    default:
      return `opacity: 1;`;
  }
};

export default opacity;
