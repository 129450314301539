import { gradientVariants } from "theme/constants";

const backgroundImage = ({
  $gradientVariant,
  theme: {
    colors: { fnDarkBlue, fnDarkerBlue },
  },
}) => {
  switch ($gradientVariant) {
    case gradientVariants.profileHeader:
      return `background: radial-gradient(ellipse farthest-side at bottom center,#eceff0 0%,rgba(255, 255, 255, 0.8) 100%);`;
    case gradientVariants.profile:
      return `background-image: linear-gradient(to right, #13426b, #124976, #0f5182, #0a588d, #016099);`;
    default:
      return `background-image: linear-gradient(197deg,${fnDarkBlue} 0%,${fnDarkerBlue} 100%);`;
  }
};

export default backgroundImage;
