import { useEffect, useMemo, useState } from "react";

const useIsHoverFocused = (...refs) => {
  const isHovered = useIsHovered(...refs);
  const isFocused = useIsfocused(...refs);

  const isHoverFocused = useMemo(
    () => isHovered || isFocused,
    [isFocused, isHovered]
  );

  return isHoverFocused;
};

const useIsHovered = (...refs) => {
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    refs.forEach((ref) => {
      const current = ref?.current;
      if (!current) return;
      if (!current.onmouseover)
        current.onmouseover = () => {
          setIsHovered(current);
          return;
        };
      if (!current.onmouseout) current.onmouseout = () => setIsHovered(false);
    });
  }, [refs]);

  return isHovered;
};

const useIsfocused = (...refs) => {
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    refs.forEach((ref) => {
      const current = ref?.current;
      if (!current) return;
      if (!current.onfocus)
        current.onfocus = () => {
          setIsFocused(current);
          return;
        };
      if (!current.onblur) current.onblur = () => setIsFocused(false);
      if (document.activeElement === current) {
        setIsFocused(current);
        return;
      }
    });
  }, [refs]);

  return isFocused;
};

export default useIsHoverFocused;
export { useIsHovered, useIsfocused };
