import React, { createContext, useReducer } from "react";

import clone from "lodash/clone";

import FnrEnvironment from "Utilities/FnrEnvironment";

import signupsActions from "./actions.js";
import { devInitialState } from "./state.dev.js";
import { convertSignupListItemAmountsToDollars } from "./utils/convertSignupListItemAmountsToDollars.js";

/*
let initialState123 = {
	navigation: {
		view: 'list', //list, signup, edit
		previous: false,
		user: null,
	},
	signup: {
		listID: null,
		signupList: null,
		userItems: null,
		updatingItem: false,
		wasJustCancelled: false,
		error: null,
		pendingUpdateItem: null,
	},
	edit: {
		signupList: null,
		listID: null,
	}
};
*/

let initialState = {
  view: "list",
  currentSignupList: { signupItems: [], options: {} },
  additionalInfo: false,
  allSignupLists: null,
  editingSignupList: null,
  userSignupItems: null,
  signupListID: null,
  sendingSignupForItem: false,
  wasJustCancelled: false,
  lastAmountBeforeCancelled: 0,
  error: false,
  sendingSignupFromConfirmation: false,
  user: {},
  pendingSignupItemID: false,
  editMode: false,
  newSignupList: false,
  showingSignupsListHolder: false,
  duplicatingSignup: false,
  duplicateSignupOptions: null,
  defaultVerbs: null,
  allVerbTypes: null,
  showArchivedSignups: false,
  hasArchivedSignups: false,
  expandedItemID: null,
  showConfetti: 0,
  anonymousUserClearedData: null,
  funds: null,
  hasFundIt: false,
  hasSignupPayments: false,
  hasFNP: false,
  hasProfileFields: false,
  hasFamilyRegistration: false,
  showDeletePaidSignupModal: false,
  canManageSignupPayments: null,
  paymentAmountNeeded: null, // in cents!
  fetchPriceFlag: false, // set to true when signup is initialized
  network: null,
  familyRegistrationIsLive: null,
  canViewFamilyRegistrationAdminPanel: false,
  canEditMembershipStatusOptions: null,
  networkHasFamilyRegistration: null,
  networkHasMembershipStatuses: null,
  networkHasFundIt: null,
  initialDataLoaded: false,
  extendedFieldInputResponses: {},
  sharedExtendedFieldInputResponses: {},
  extendedFieldInputErrors: {},
  sharedExtendedFieldInputErrors: [],
  showPollFields: true,
  hasUnsavedEdits: false,
  triggerRespondentRefresh: false,
  memberRespondents: {},
  signupListTemplates: [],
  isSignupEditMode: false,
  currentSignupItem: null,
  needToUpdateSignupLists: false,
  publicHeaderHeight: null,
};

const blankSignupList = {
  title: "",
  description: "",
  signupListTypeID: 1,
  signupItems: [
    {
      tempID: 1,
      title: "",
      maxSingleUserSlots: 5,
      totalSlots: 20,
      hasSignupCost: false,
      amount: 0,
      costType: "",
    },
    {
      tempID: 2,
      title: "",
      maxSingleUserSlots: 5,
      totalSlots: 20,
      hasSignupCost: false,
      amount: 0,
      costType: "",
    },
    {
      tempID: 3,
      title: "",
      maxSingleUserSlots: 5,
      totalSlots: 20,
      hasSignupCost: false,
      amount: 0,
      costType: "",
    },
  ],
};

const signupsAppReducer = (state, action) => {
  let newState = { ...state };
  const { type, additionalInfo } = action;

  switch (type) {
    case signupsActions.SET_ADDITIONAL_INFO:
      return { ...state, additionalInfo };
    case "Show Signups List Holder":
      newState.showingSignupsListHolder = true;
      return newState;
    case "Hide Signups List Holder":
      newState.showingSignupsListHolder = false;
      return newState;

    case "Remove Current Signup List":
      newState.currentSignupList = initialState.currentSignupList;
      return newState;

    case "Set Current Signup List": {
      const signupListConverted = convertSignupListItemAmountsToDollars(
        action.signupList
      );
      newState.currentSignupList = clone(signupListConverted) || {};

      if (action.userSignupItems)
        newState.userSignupItems = action.userSignupItems;
      if (action.user) {
        newState.user = action.user;
        if (action.newUser) {
          newState.user.wasJustAddedToHH = true;
        }
      }
      if (action.allSignupLists)
        newState.allSignupLists = action.allSignupLists;
      newState.showingSignupsListHolder = false;

      if (action.allSignupListTypes)
        newState.allVerbTypes = action.allSignupListTypes;
      if (action.paymentAmountNeeded)
        newState.paymentAmountNeeded = action.paymentAmountNeeded;
      newState.fetchPriceFlag = true; // Update the price anytime the signup is updated.
      if (action.network) newState.network = action.network;
      if (action.triggerRespondentRefresh)
        newState.triggerRespondentRefresh = true;

      newState.currentSignupList.hasDisabledFundItViaItem = false;

      const { extendedFieldInputResponses, sharedExtendedFieldInputResponses } =
        addUserExtendedFieldResponses(newState);
      newState.extendedFieldInputResponses = extendedFieldInputResponses;
      newState.sharedExtendedFieldInputResponses =
        sharedExtendedFieldInputResponses;

      return newState;
    }

    case "Update All Signup Lists":
      if (action.allSignupLists)
        newState.allSignupLists = action.allSignupLists;
      return newState;

    case "Item Signup Updating":
      newState.sendingSignupForItem = true;
      newState.wasJustCancelled = false;
      newState.error = false;
      if (action.pendingSignupItemID)
        newState.pendingSignupItemID = action.pendingSignupItemID;
      return newState;

    case "Item Signup Updating From Confirmation":
      newState.sendingSignupFromConfirmation = true;
      newState.sendingSignupForItem = false;
      newState.wasJustCancelled = false;
      newState.error = false;
      return newState;

    case "Item Signup Error":
      newState.sendingSignupForItem =
        newState.sendingSignupFromConfirmation = false;
      newState.error = action.error;
      newState.pendingSignupItemID = false;
      if (action.newCounts)
        action.newCounts.forEach((newCount) => {
          newState.currentSignupList.signupItems.find(
            (signupItem) => signupItem.ID === newCount.itemID
          ).slotsTaken = newCount.slotsTaken;
        });
      return newState;

    case "Item Signup Updated":
      newState.sendingSignupForItem =
        newState.sendingSignupFromConfirmation = false;
      newState.userSignupItems = action.userSignupItems;
      newState.pendingSignupItemID = false;
      if (action.user) newState.user = action.user;
      if (action.cancelled) newState.wasJustCancelled = true;
      newState.lastAmountBeforeCancelled = 0;

      Object.entries(newState.userSignupItems).forEach(([signupItemID]) => {
        const findNewSlotsTaken = newState.currentSignupList.signupItems.find(
          (signupItem) => Number(signupItem.ID) === Number(signupItemID)
        );
        if (findNewSlotsTaken) {
          newState.lastAmountBeforeCancelled += findNewSlotsTaken.slotsTaken; // save prev slot
          findNewSlotsTaken.slotsTaken = action.newCounts;
        }
      });

      if (action.paymentAmountNeeded !== undefined) {
        newState.paymentAmountNeeded = action.paymentAmountNeeded;
      }

      newState.fetchPriceFlag = true; // Update the price anytime the signup is updated.
      return newState;

    case signupsActions.SET_FETCH_PRICE_FLAG:
      return { ...state, fetchPriceFlag: action.payload };

    case "Cancelled From Confirmation Email":
      newState.wasJustCancelled = true;
      return newState;

    case "Cancel User Signup":
      newState.sendingSignupForItem =
        newState.sendingSignupFromConfirmation = false;
      newState.pendingSignupItemID = false;
      return newState;

    /*
		case "Navigate To Signup View":
			newState.view = 'signup';
			newState.currentSignupList = newState.editingSignupList = null;
			newState.signupListID = action.signupListID;
			if (action.signupList) newState.currentSignupList = JSON.parse(JSON.stringify(action.signupList))
			if (action.allSignupLists) newState.allSignupLists = action.allSignupLists;
			newState.editMode = false;
			newState.newSignupList = false;
			newState.showingSignupsListHolder = false;
			newState.duplicatingSignup = false;
			newState.duplicateSignupOptions = null;
			return newState;
			*/

    case "Other User Signup Updated": {
      if (action.signupList) {
        const signupListConverted = convertSignupListItemAmountsToDollars(
          action.signupList
        );
        newState.currentSignupList = JSON.parse(
          JSON.stringify(signupListConverted)
        );
      }
      if (action.allSignupLists)
        newState.allSignupLists = action.allSignupLists;
      return newState;
    }

    case "Reset Signups State":
      newState = { ...initialState };
      return newState;

    case "setSignupListsData":
      newState.allSignupLists = action.payload.signupLists;
      newState.defaultVerbs = action.payload.defaultVerbs;
      newState.signupListTemplates = action.payload.signupListTemplates;

      newState.hasArchivedSignups = newState.allSignupLists?.find(
        (signupList) => signupList.signupListStatusID === 4
      );
      newState.funds = action.payload.funds;
      newState.canManageSignupPayments = action.payload.canManageSignupPayments;
      newState.hasFundIt = action.payload.hasFundIt;
      newState.hasFNP = action.payload.hasFNP;
      newState.hasSignupPayments = action.payload.hasSignupPayments;
      newState.hasProfileFields = action.payload.hasProfileFields;
      newState.familyRegistrationIsLive =
        action.payload.familyRegistrationIsLive;
      newState.hasFamilyRegistration = action.payload.hasFamilyRegistration;
      newState.canViewFamilyRegistrationAdminPanel =
        action.payload.canViewFamilyRegistrationAdminPanel || false;
      newState.canEditMembershipStatusOptions =
        action.payload.canEditMembershipStatusOptions || false;
      newState.networkHasFamilyRegistration =
        action.payload.networkHasFamilyRegistration || false;
      newState.networkHasMembershipStatuses =
        action.payload.networkHasMembershipStatuses || false;
      newState.networkHasFundIt = action.payload.networkHasFundIt || false;
      newState.initialDataLoaded = true;
      newState.needToUpdateSignupLists = false;
      if (action.payload.user) {
        newState.user = action.payload.user;
      }
      return newState;

    case "Create New Signup":
      newState.signupListID = null;
      newState.currentSignupList = null;
      //newState.editingSignupList = JSON.parse(JSON.stringify(blankSignupList));
      return newState;

    case "Update Edit Signup Info":
      newState.currentSignupList[action.key] = action.value;
      if (action.key === "title") {
        newState.allSignupLists.find(
          ({ ID }) => ID === newState.currentSignupList.ID
        ).title = action.value;
      }
      return newState;

    case "SignupList Item Edited": {
      let findItem = newState.currentSignupList.signupItems.find((item) =>
        action.ID ? item.ID === action.ID : item.tempID === action.tempID
      );
      findItem[action.key] = action.value;
      return newState;
    }

    case "updateCostType": {
      if (action.payload) {
        if (!newState.currentSignupList.fundIt?.cost) {
          newState.currentSignupList.fundIt.cost = {
            costType: "",
          };
        }

        newState.currentSignupList.fundIt.cost.costType = action.payload;
        newState.currentSignupList.fundIt.hasSignupCost = true;

        newState.currentSignupList.signupItems.forEach((item) => {
          item.costType = action.payload;
          item.hasSignupCost = true;
        });
      } else {
        newState.currentSignupList.fundIt.costType = null;
        newState.currentSignup.fundIt.hasSignupCost = false;
        newState.currentSignupList.signupItems.forEach((item) => {
          item.costType = null;
          item.hasSignupCost = false;
        });
      }

      return newState;
    }

    case "updateAllSignupListItems": {
      newState.currentSignupList.signupItems.forEach((item) => {
        item[action.key] = action.value;
      });

      return newState;
    }

    case "replaceAllSignupListItems": {
      newState.currentSignupList.signupItems = action.payload;
      return newState;
    }

    case "Reorder Signuplist Items": {
      newState.currentSignupList.signupItems = [...action.value];
      return newState;
    }

    case "Update Confirmed Signups":
      if (newState.allSignupLists) {
        const refToUpdate = newState.allSignupLists.find(
          (list) => list.ID === Number(action.ID)
        );
        if (refToUpdate) {
          refToUpdate.totalConfirmedSignups = action.value;
        }
      }
      return newState;

    case "Remove an Item From List": {
      // update all signups to have accurate signup count
      const itemRespondentsCount = newState.currentSignupList.signupItems.find(
        (item) => item.ID === action.ID
      ).numberOfRespondents;
      const currentSignupListID = newState.currentSignupList.ID;

      const allSignupLists = newState.allSignupLists.map((item) => {
        if (item.ID === currentSignupListID) {
          item.totalConfirmedSignups =
            item.totalConfirmedSignups - itemRespondentsCount;
        }
        return item;
      });
      newState.allSignupLists = allSignupLists;

      // remove signup item from current signup list
      newState.currentSignupList.signupItems =
        newState.currentSignupList.signupItems.filter((item) =>
          action.ID
            ? !item.ID || item.ID !== action.ID
            : !item.tempID || item.tempID !== action.tempID
        );
      newState.needToUpdateSignupLists = true;
      return newState;
    }

    case "Add Blank Signup Item":
      newState.currentSignupList.signupItems.push(action.signupItem);
      return newState;

    case "Edit Signup List Option":
      newState.currentSignupList.options[action.name] = action.value;
      return newState;

    case "Duplicate Signup":
      newState.editingSignupList = JSON.parse(
        JSON.stringify(newState.currentSignupList)
      );
      return newState;

    case "Edit Duplicate Signup Option":
      if (newState.duplicateSignupOptions)
        newState.duplicateSignupOptions[action.name] = action.value;
      return newState;

    case "Update Custom Email Confirmation Data":
      if (!newState.currentSignupList) return newState;
      newState.currentSignupList.customEmailConfirmationData = action.data;
      return newState;

    case "Set Default Verbs":
      newState.defaultVerbs = action.verbs;
      return newState;

    case "Set All Signup List Types":
      newState.allVerbTypes = action.types;
      return newState;

    case "Change Status":
      newState.currentSignupList.signupListStatusID = action.status;
      newState.allSignupLists.find(
        (list) => list.ID === Number(action.ID)
      ).signupListStatusID = action.status;

      newState.hasArchivedSignups = newState.allSignupLists.find(
        (signupList) => signupList.signupListStatusID === 4
      );
      newState.showArchivedSignups = newState.hasArchivedSignups
        ? newState.showArchivedSignups
        : false;
      return newState;

    case "toggleShowArchivedSignups":
      newState.showArchivedSignups = !newState.showArchivedSignups;
      return newState;

    case "setShowArchivedSignups":
      newState.showArchivedSignups = action.payload;
      return newState;

    case "Change Signup Type":
      newState.currentSignupList = JSON.parse(
        JSON.stringify(newState.currentSignupList)
      );
      newState.currentSignupList.signupListVerbs = action.newVerbs;
      newState.currentSignupList.signupListTypeID = action.newVerbs.ID;
      return newState;

    case "Update Item Group Link": {
      newState.currentSignupList = updateSignupListItemGroupLink({
        currentSignupList: newState.currentSignupList,
        linkedToGroup: action.linkedToGroup,
        signupItemID: action.signupItemID,
      });
      return newState;
    }

    case "Set Manual Reminders Sent":
      newState.currentSignupList.manualRemindersSent =
        action.manualRemindersSent;
      return newState;

    case "Set Payment Reminders Sent":
      newState.currentSignupList.paymentRemindersSent =
        action.paymentRemindersSent;
      return newState;

    case "expandedItemID": {
      newState.expandedItemID = action.ID;
      return newState;
    }

    case "user": {
      newState.user = action.payload;
      return newState;
    }

    case "anonymousUserClearedData": {
      newState.anonymousUserClearedData = action.payload;
      return newState;
    }

    case signupsActions.SET_USER_SIGNUP_ITEMS: {
      newState.userSignupItems = action.payload;
      return newState;
    }

    case "showConfetti": {
      if (action.payload) {
        newState.showConfetti = newState.showConfetti + 1;
      }
      return newState;
    }

    case "Update Signup Description": {
      newState.currentSignupList.description = action.value;
      return newState;
    }

    case "Add New Household User": {
      const user = { ...state?.user } || {};
      const { newUser, householdID } = action.payload;
      newState.user = {
        ...user,
        householdID: householdID || 0,
        canSignupHouseholdMembers: true,
      };
      newState.user.household.push({
        userID: newUser.ID,
        fname: newUser.fname,
        lname: newUser.lname,
        wasJustAddedToHH: true,
      });
      newState.triggerRespondentRefresh = true;
      return newState;
    }

    case "setMemberRespondents": {
      const respondents = action.payload;
      const memberRespondents = {};
      respondents.forEach((r) => (memberRespondents[r.userID] = r));
      newState.memberRespondents = memberRespondents;
      return newState;
    }

    case "setFundsList": {
      newState.funds = action.payload;
      return newState;
    }

    case "setSignupListCostsMoney": {
      newState.currentSignupList.costsMoney = action.payload;
      return newState;
    }

    case signupsActions.SET_IS_PER_SIGNUP_COST_TYPE: {
      newState.currentSignupList.isPerSignupCostType = action.payload;
      return newState;
    }

    case "setSignupListPerSignupCostTypeAmount": {
      newState.currentSignupList.perSignupCostTypeAmount = action.payload;
      return newState;
    }
    case "setSignupListPerSignupCostTypeAmounts": {
      newState.currentSignupList.fundIt.cost = {
        amounts: action.payload,
        costType: "PerSignup",
      };
      return newState;
    }

    case "setSignupFund": {
      newState.currentSignupList.fundIt.fund = action.payload;
      return newState;
    }

    case "setShowDeletePaidSignupModal": {
      newState.showDeletePaidSignupModal = action.payload;
      return newState;
    }

    case signupsActions.SET_PAYMENT_AMOUNT_NEEDED: {
      return { ...state, paymentAmountNeeded: action.payload };
    }

    case "setIsSuggestedDonation": {
      newState.currentSignupList.fundIt.isSuggestedDonation = action.payload;
      return newState;
    }

    case "updateExtendedFieldInputResponse": {
      const { userID, fieldID, value, signupItemID } = action.payload;
      if (!newState.extendedFieldInputResponses)
        newState.extendedFieldInputResponses = {};
      if (newState.extendedFieldInputResponses[userID]) {
        // newState.extendedFieldInputResponses[userID][signupItemID][fieldID] =
        //   value;
        newState.extendedFieldInputResponses[userID][fieldID] = value;
      } else {
        newState.extendedFieldInputResponses[userID] = {};
        // newState.extendedFieldInputResponses[userID][signupItemID] = {};
        // newState.extendedFieldInputResponses[userID][signupItemID][fieldID] =
        //   value;
        newState.extendedFieldInputResponses[userID][fieldID] = value;
      }
      if (Array.isArray(state.extendedFieldInputErrors[userID])) {
        newState.extendedFieldInputErrors[userID] =
          state.extendedFieldInputErrors[userID].filter(
            (erredField) => erredField !== fieldID
          );
      }
      newState.hasUnsavedEdits = true;
      // newState.hasUnsavedExtendedFieldData
      // newState.extendedFieldInputResponses[userID][fieldID] = value;
      return newState;
    }

    case "updateSharedExtendedFieldInputResponses": {
      const { fieldID, value, signupItemID } = action.payload;
      // newState.sharedExtendedFieldInputResponses[signupItemID][fieldID] = value;
      newState.sharedExtendedFieldInputResponses[fieldID] = value;
      newState.hasUnsavedEdits = true;

      if (Array.isArray(state.sharedExtendedFieldInputErrors)) {
        newState.sharedExtendedFieldInputErrors =
          state.sharedExtendedFieldInputErrors.filter(
            (erredField) => erredField !== fieldID
          );
      }

      return newState;
    }

    case "clearExtendedFieldInputResponse": {
      newState.extendedFieldInputResponses = {};
      newState.sharedExtendedFieldInputResponses = {};
      newState.extendedFieldInputErrors = {};
      newState.sharedExtendedFieldInputErrors = [];

      const { extendedFieldInputResponses, sharedExtendedFieldInputResponses } =
        addUserExtendedFieldResponses(newState);
      newState.extendedFieldInputResponses = extendedFieldInputResponses;
      newState.sharedExtendedFieldInputResponses =
        sharedExtendedFieldInputResponses;

      newState.hasUnsavedEdits = false;
      return newState;
    }

    case signupsActions.SET_SHOW_POLL_FIELDS: {
      newState.showPollFields = action.payload;
      return newState;
    }

    case "setFieldValidationErrors": {
      newState.extendedFieldInputErrors = action.payload;
      return newState;
    }

    case "setSharedFieldValidationErrors": {
      newState.sharedExtendedFieldInputErrors = action.payload;
      return newState;
    }

    case "updateHasUnsavedEdits": {
      newState.hasUnsavedEdits = action.payload;
      return newState;
    }

    case "updateTriggerRespondentRefresh": {
      newState.triggerRespondentRefresh = action.payload;
      return newState;
    }

    case "setIsSignupEditMode": {
      newState.isSignupEditMode = action.payload;
      return newState;
    }

    case "setCurrentSignupItem": {
      newState.currentSignupItem = action.payload;
      return newState;
    }

    case "updateSlotsTaken": {
      const { slotsTaken, signupItemID } = action.payload;
      const signupItemIndex = newState.currentSignupList.signupItems.findIndex(
        (item, i) => item.ID === signupItemID
      );
      if (newState.currentSignupList.signupItems[signupItemIndex]) {
        newState.currentSignupList.signupItems[signupItemIndex].slotsTaken =
          slotsTaken;
      }

      return newState;
    }

    case "updatePollSlotsTaken": {
      const { signupItemID } = action.payload;
      const signupItems = newState.currentSignupList.signupItems.map((item) => {
        const isUpdatedItem = signupItemID === item.ID;

        if (isUpdatedItem) return item;

        return {
          ...item,
          slotsTaken: 0,
        };
      });

      newState.currentSignupList.signupItems = signupItems;
      return newState;
    }

    case "removeOldVoteFromSlotsTaken": {
      const { oldVoteItemID } = action.payload;
      const signupItems = newState.currentSignupList.signupItems.map((item) => {
        if (item.ID !== oldVoteItemID) return item;

        const newSlotsTaken = item.slotsTaken - 1;
        item.slotsTaken = newSlotsTaken >= 0 ? newSlotsTaken : 0;
        return item;
      });
      newState.currentSignupList.signupItems = signupItems;
      return newState;
    }

    case "setPublicHeaderHeight": {
      newState.publicHeaderHeight = action.payload;
      return newState;
    }

    case "Update All Signups Item Available Slots": {
      const allSignupLists = newState.allSignupLists.map((item) => {
        if (item.ID === action.payload.ID) {
          item.totalAvailableSlots =
            item.totalAvailableSlots + action.payload.additionalAvailableSlots;
        }
        return item;
      });
      newState.allSignupLists = allSignupLists;
      return newState;
    }

    case "clearWasJustAddedToHHFlags": {
      if (newState.user.household?.length) {
        const newHousehold = newState.user.household.map((u) => {
          return {
            ...u,
            wasJustAddedToHH: false,
          };
        });
        newState.user.household = newHousehold;
      }
      return newState;
    }

    default:
      return newState;
  }
};

export const SignupsAppState = createContext();

export function SignupsAppStateProvider({ children }) {
  if (FnrEnvironment.shouldUseDevState())
    initialState = { ...initialState, ...devInitialState };
  return (
    <SignupsAppState.Provider
      value={useReducer(signupsAppReducer, initialState)}
    >
      {children}
    </SignupsAppState.Provider>
  );
}

function updateSignupListItemGroupLink({
  currentSignupList,
  linkedToGroup,
  signupItemID,
}) {
  const index = currentSignupList.signupItems.findIndex(
    (item) => item.ID === signupItemID
  );
  currentSignupList.signupItems[index].linkedToGroup = linkedToGroup;
  return currentSignupList;
}

export function useSignups() {
  const context = React.useContext(SignupsAppState);

  if (!context) {
    throw new Error(
      "useSignups must be used inside a <SignupsAppStateProvider />"
    );
  }

  return context;
}

const addUserExtendedFieldResponses = (state) => {
  let extendedFieldInputResponses = state?.extendedFieldInputResponses || {};
  let sharedExtendedFieldInputResponses =
    state?.sharedExtendedFieldInputResponses || {};
  const fields = getAllExtendedFields(state?.currentSignupList?.signupItems);
  // const responses = getAllExtendedFieldResponses(state?.user);
  const extendedFieldResponses = state?.user?.extendedFieldResponses;
  const household = state?.user?.household;
  const userResponses = addResponses(
    extendedFieldResponses,
    fields,
    state?.user?.ID
  );
  let householdUserResponses = {};
  let householdSharedResponses = {};
  if (household) {
    for (const member of household) {
      const { extendedFieldResponses: memberFieldResponses } = member;
      const memberResponses = addResponses(
        memberFieldResponses,
        fields,
        member.userID
      );
      householdUserResponses = {
        ...householdUserResponses,
        ...memberResponses.extendedFieldInputResponses,
      };
      householdSharedResponses = {
        ...householdSharedResponses,
        ...memberResponses.sharedExtendedFieldInputResponses,
      };
    }
  }
  return {
    extendedFieldInputResponses: {
      ...userResponses.extendedFieldInputResponses,
      ...householdUserResponses,
    },
    sharedExtendedFieldInputResponses: {
      ...userResponses.sharedExtendedFieldInputResponses,
      ...householdSharedResponses,
    },
  };
};

const getAllExtendedFields = (signupItems) => {
  let ef = {
    all: {},
    user: {},
    shared: {},
  };
  // const fieldIDs = [];
  // const fields = [];
  if (!signupItems) return ef;
  for (const item of signupItems) {
    const { extendedFields } = item;
    if (!extendedFields) break;
    for (const field of extendedFields) {
      if (field.fieldID in ef.all) continue;
      ef.all[field.fieldID] = field;
      if (field.entityType === "user") ef.user = field;
      else ef.shared = field;
      // if ()
    }
  }
  return ef;
};

const addResponses = (extendedFieldResponses, fields, userID) => {
  let extendedFieldInputResponses = {};
  let sharedExtendedFieldInputResponses = {};
  if (extendedFieldResponses) {
    for (const fieldID in extendedFieldResponses) {
      const response = extendedFieldResponses[fieldID];
      if (!response) continue;
      if (!(fieldID in fields.all)) continue;
      const field = fields.all[fieldID];
      if (!field) continue;
      if (field.entityType === "user") {
        if (!extendedFieldInputResponses[userID])
          extendedFieldInputResponses[userID] = {};
        extendedFieldInputResponses[userID][fieldID] = convertResponseData(
          response,
          field
        );
      } else {
        sharedExtendedFieldInputResponses[fieldID] = convertResponseData(
          response,
          field
        );
      }
    }
  }
  return { extendedFieldInputResponses, sharedExtendedFieldInputResponses };
};

const getAllExtendedFieldResponses = (user) => {
  let resp = {};
  const r = [];
  const { extendedFieldResponses, household } = user;
  if (extendedFieldResponses) {
    for (const fieldID in extendedFieldResponses) {
      r.push(extendedFieldResponses[fieldID]);
    }
  }
};

function convertResponseData_Other(responses, signupItem) {
  const { extendedFields } = signupItem;
  const resp = {};
  for (const fieldID in responses) {
    const response = responses[fieldID];
    const field = extendedFields.find(
      (f) => f.fieldID.toString() === fieldID.toString()
    );
    if (field) {
      switch (field.type) {
        case "picker": {
          resp[fieldID] = response.value.values.map((r) =>
            parseInt(r.optionListValueID)
          );
          break;
        }
        case "address":
        case "date":
          resp[fieldID] = response.value;
          break;
        case "text":
        case "number": {
          resp[fieldID] = response.value.value;
          break;
        }
        default:
          break;
      }
    }
  }
  return resp;
}

const convertResponseData = (response, field) => {
  switch (field.type) {
    case "picker": {
      // single select
      if (response.value || response.value.length) {
        return (response.value.values || []).map((r) =>
          parseInt(r.optionListValueID)
        );
      }

      // multiselect
      if (response.values || response.values.length) {
        return response.values;
      }

      return null;
    }
    case "address":
    case "date":
      return response.value;
    case "text":
    case "number": {
      return response.value.value;
    }
    case "file": {
      return response.value.fileName;
    }
    default:
      return null;
  }
};
