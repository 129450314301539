import { useEffect } from "react";

/**
 * Add data-cancel-click-away={["cancelKey1", "cancelkey2"] || "cancelKey1,cancelKey2"} to any element that should not trigger the useOnClickOutside handler.
 * @param {Element} elem
 */
const parentHasNoClickAway = (elem, cancelKey) => {
  if (!elem || !cancelKey) return false;

  if (elem.tagName.toLowerCase() === "body") return false;

  if (typeof elem.dataset.cancelClickAway === "string") {
    let cancelKeys = elem.dataset.cancelClickAway.split(",");
    if (cancelKeys.includes(cancelKey)) return true;
  }

  return parentHasNoClickAway(elem.parentNode, cancelKey);
};

/**
 *
 * @param {import("react").Ref} ref
 * @param {function} handler - MUST be wrapped in a callback.
 * @param {string} cancelKey Add data-cancel-click-away={["cancelKey1", "cancelkey2"] || "cancelKey1,cancelKey2"}  to any element that should not trigger the useOnClickOutside handler.
 */
const useOnClickOutside = (ref, handler, cancelKey) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements

      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        parentHasNoClickAway(event.target, cancelKey)
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, cancelKey]);
};

export { parentHasNoClickAway, useOnClickOutside };
export default useOnClickOutside;
