import { bool, node, number, string } from "prop-types";

import styled from "styled-components";

const SvgWrapper = styled.div`
  display: flex;
  position: relative;
  ${({ $top }) => ($top ? `top: ${$top}px;` : "")}
  align-items: center;
  justify-content: center;
  width: ${({ $size, $doubleWidth }) => `${$size * ($doubleWidth ? 2 : 1)}px;`};
  height: ${({ $size }) => `${$size}px;`};
  ${({ $rotation }) => ($rotation ? `transform: rotate(${$rotation}deg);` : "")}
  transition: top 300ms, width 300ms, height 300ms;
`;

const FnrSvg = ({ size, top, rotation, doubleWidth, viewBox, children }) => (
  <SvgWrapper
    $size={size}
    $top={top}
    $rotation={rotation}
    $doubleWidth={doubleWidth}
  >
    <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  </SvgWrapper>
);

FnrSvg.propTypes = {
  size: number,
  top: number,
  rotation: number,
  doubleWidth: bool,
  viewBox: string,
  children: node,
};

export default FnrSvg;
